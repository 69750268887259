.sidebar-nav {
    position: relative;
    padding-left: 20px;

    $elm: &;

    // ul tag

    &__list {
        margin-bottom: 1em;
    }

    // actief streepje, staat als losse span zodat deze kan animeren

    &__indicator {
        left: 0;
        top: 0;
        height: rem-calc(40);
        width: rem-calc(4);
        background: map-deep-get($colors, "blue", "dark");
        display: inline-block;
        position: absolute;

        @at-root body.body--loaded & {
            transition: $transition-default;
        }
    }

    // title

    &__title {
        margin-bottom: 1em;
        margin-top: 2em;
        color: map-deep-get($colors, "gray", "default");
    }

    // list item

    &__item {
        min-height: rem-calc(40);
    }

    // a tag

    &__link {
        color: map-deep-get($colors, "black", "default");
        font-family: $headings-font-family;
        font-size: rem-calc(12);
        font-weight: 900;
        letter-spacing: 0.05em;
        line-height: 1.33em;
        padding: 0.66em 0;
        //display: inline-flex;
        display: flex;
        text-transform: uppercase;
        align-items: center;
    }

    // inline svg

    &__icon {
        display: inline-flex;
        line-height: 1em;
        height: 2.2em;
        margin-right: .5em;
        width: 2.2em;
        align-items: center;

        svg {
            fill: map-deep-get($colors, "black", "default");
            height: 100%;
            max-height: rem-calc(18);
            max-width: rem-calc(25);
            width: 100%;
        }
    }

    ///* stylelint-disable */
    //.icon--meet-up {
    //    .st0 {
    //        fill: map-deep-get($colors, "blue", "default");
    //    }
    //}
    ///* stylelint-enable */

    // actief item

    &__item:not(#{$elm}__item--active):hover {
        #{$elm}__link {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &__item--active {
        #{$elm}__link {
            color: map-deep-get($colors, "blue", "dark");
        }

        #{$elm}__icon svg {
            fill: map-deep-get($colors, "brand", "primary");
        }

        /* stylelint-disable */
        #icon--meet-up {
            .st0 {
                fill: map-deep-get($colors, "gray", "default");
            }
            .st1 {
                fill: map-deep-get($colors, "blue", "default");
            }
        }
        /* stylelint-enable */
    }
}