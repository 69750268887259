﻿h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family: $headings-font-family;
    margin: 0;
}

h1, .h1 {
    // margin-bottom: 0.5em;
    font-size: rem-calc(42);
    line-height: 1.2;
    letter-spacing: 0.019em;
}

h2, .h2 {
    // margin-bottom: 0.5em;
    font-size: rem-calc(24);
    line-height: 1.2;
    letter-spacing: 0.019em;
}

h3, .h3 {
    // margin-bottom: 0.5em;
    font-size: rem-calc(18);
    line-height: 1.2;
    letter-spacing: 0.019em;
}

h4, .h4 {
    // margin-bottom: 0.5em;
    font-size: rem-calc(16);
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: 0.016em;
}

h5, .h5 {
    // margin-bottom: 0.5em;
    font-size: rem-calc(12);
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

h6, .h6 {
    // margin-bottom: 0.5em;
}
