.training-note {
    &__title {
        margin-bottom: rem-calc(5);
        letter-spacing: 0.018em;
        font-family: $headings-font-family;
    }
    &__content {
        white-space: pre-line;
    }
}
