/* stylelint-disable */
::-webkit-input-placeholder {
    color: map-deep-get($colors, "gray", "default");
    font-style: italic;
    -webkit-font-smoothing: antialiased;
}
::-moz-placeholder {
    color: map-deep-get($colors, "gray", "default");
    font-style: italic;
    -webkit-font-smoothing: antialiased;
}
:-ms-input-placeholder {
    color: map-deep-get($colors, "gray", "default");
    font-style: italic;
    -webkit-font-smoothing: antialiased;
}
:-moz-placeholder {
    color: map-deep-get($colors, "gray", "default");
    font-style: italic;
    -webkit-font-smoothing: antialiased;
}
/* stylelint-enable */

input, select, .form__file-input {
    background: map-deep-get($colors, "white", "default");;
    border: 1px solid map-deep-get($colors, "gray", "default");
    border-radius: 0;
    color: map-deep-get($colors, "black", "default");
    font-family: $headings-font-family;
    font-size: rem-calc(13);
    font-style: normal;
    font-weight: 800;
    height: rem-calc(50);
    letter-spacing: 0.057em;
    line-height: 1.25;
    padding: 0 rem-calc(16);
    transition: $transition-default;
    width: 100%;

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "brand", "primary");
    }
}

textarea, .textarea {
    background: map-deep-get($colors, "white", "default");;
    border: 1px solid map-deep-get($colors, "gray", "default");
    border-radius: 0;
    color: map-deep-get($colors, "black", "default");
    font-family: $font-default;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.225px;
    line-height: 15px;
    min-height: rem-calc(150);
    padding: 8px 16px;
    resize: vertical;
    transition: border .3s ease-in-out;
    width: 100%;

    &:focus {
        border: 1px solid map-deep-get($colors, "brand", "primary");
        outline: none;
    }
}

.textarea {
    display: block;
    margin-top: 5px;
}

select {
    appearance: none;
    background: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "black", "default");
    padding-right: 40px;

    &::-ms-expand {
        display: none;
    }
}

.select-wrapper {
    position: relative;

    &__select {
        height: rem-calc(50);
        color: map-deep-get($colors, "black", "default");
        border: 1px solid map-deep-get($colors, "gray", "default");
        font-weight: 500;
        padding: 0 20px;

        option {
            font-weight: 100;
        }

        &:focus {
            border: 1px solid map-deep-get($colors, "gray", "default");
        }
    }

    &__dropdown-icon {
        position: absolute;
        right: rem-calc(10);
        top: 50%;
        pointer-events: none;
        width: 0.75rem;
        height: 0.6rem;
        margin-top: -0.3rem;

        svg {
            width: 100%;
            height: 100%;
            fill: map-deep-get($colors, "blue", "dark");
            vertical-align: top;
        }
    }

    //Directly referencing option element because of select context.
    /* stylelint-disable */
    option {
        font-size: 18px;
        padding: 12px 0;
    }
    /* stylelint-enable */
}

//Radio Button styling

.radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(8);
    $radioWrapper: &;

    &__input-wrapper {
        position: relative;
        width: rem-calc(22);
        height: rem-calc(22);
        margin-right: rem-calc(8);
        float: left;
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 50%;
            background-color: map-deep-get($colors, "white", "default");;
            border: 0.7px solid map-deep-get($colors, "gray", "default");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        border-radius: 50%;
        background-color: map-deep-get($colors, "brand", "primary");
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            border: 1px solid map-deep-get($colors, "brand", "primary");

            + #{$radioWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        font-size: rem-calc(14);
        font-family: $headings-font-family;
        cursor: pointer;
        font-weight: 600;
        margin-bottom: 0;
        text-transform: uppercase;
        color: map-deep-get($colors, "blue", "dark");
    }
    /* stylelint-enable */

    &--prevent-click {
        label,
        #{$radioWrapper}__label,
        #{$radioWrapper}__input-wrapper {
            cursor: default;
        }
    }
}

.checkbox-wrapper {
    display: flex;
    align-items: start;
    margin-bottom: rem-calc(8);
    $checkboxWrapper: &;

    &__input-wrapper {
        position: relative;
        cursor: pointer;
        flex-shrink: 0;
        line-height: rem-calc(28);
    }

    &__input-dot {
        width: rem-calc(30);
        height: rem-calc(30);
        background-color: map-deep-get($colors, "white", "default");;
        border: 1px solid map-deep-get($colors, "gray", "primary");
        border-radius: $border-radius;
        margin-right: rem-calc(20);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            opacity: 0;
            fill: map-deep-get($colors, "brand", "primary");
            width: 70%;
            height: 70%;
        }
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 0;
        font-size: 0;
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$checkboxWrapper}__input-dot {
                svg {
                    opacity: 1;
                }
            }
        }
    }

    .form__label {
        align-self: center;
    }

    &__label, label {
        cursor: pointer;
        align-self: center;
        font-weight: 400;
        text-transform: none;
    }
    /* stylelint-enable */
}

.form {
    &__button {
        @extend .button--primary;
    }

    /* stylelint-disable */
    &__file-input {
        position: relative;

        .form__input {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
    }

    &__file-value {
        bottom: 0;
        display: block;
        position: absolute;
        top: 0;
        right: rem-calc(16);
        left: 0;
        padding: 0 rem-calc(16);
        line-height: rem-calc(50);
        overflow: hidden;
        white-space: nowrap;
        color: map-deep-get($colors, "blue", "dark");
        font-family: $headings-font-family;
        font-size: rem-calc(12);
        font-weight: 600;
        text-transform: uppercase;

        &--done {
            color: map-deep-get($colors, "black", "default");
            text-transform: none;
        }

        &::after {
            bottom: 0;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            width: 10%;
        }
    }
    /* stylelint-enable */
}

.input-wrapper {
    position: relative;
}

.input-delete {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: map-deep-get($colors, "white", "default");
    border: 1px solid map-deep-get($colors, "gray", "default");
    border-left: 0;
    border-radius: 0;
    cursor: pointer;

    svg {
        width: 0.75rem;
        height: 0.75rem;
        fill: map-deep-get($colors, "validation", "invalid");
    }
}

fieldset {
    position: relative;
}

fieldset[disabled]::after {
    content: " ";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.3) url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==");
}
