.declaration-block {
    &__date {
        color: map-deep-get($colors, "gray", "dark");
        display: inline-block;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
    }

    &__status {
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        font-weight: 400;
        margin-right: 0.5em;
        -webkit-font-smoothing: antialiased;
    }

    &__status-indicator {
        border-radius: 50%;
        display: inline-block;
        height: 1em;
        margin-right: 0.5em;
        vertical-align: middle;
        width: 1em;

        &--done {
            background: map-deep-get($colors, "status", "done");
        }

        &--in-progress {
            background: map-deep-get($colors, "status", "progress");
        }

        &--in-concept {
            background: map-deep-get($colors, "status", "concept");
        }

        &--denied {
            background: map-deep-get($colors, "status", "denied");
        }
    }

    &__value {
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        font-family: $headings-font-family;
        font-size: rem-calc(14);
        font-weight: 600;
        margin-right: 2em;
        margin-top: 1em;
        text-transform: uppercase;
    }

    &__description {
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        font-family: $headings-font-family;
        font-size: rem-calc(14);
        font-weight: 600;
        margin-top: 1em;
        text-transform: uppercase;
    }

    &__line {
        padding: rem-calc(5);

        &--striped {
            background-color: map-deep-get($colors, "gray", "light");
        }

        &-costs {
            display: block;
            text-align: right;
        }
    }
}
