.sidebar {
    background: map-deep-get($colors, "white", "default");
    padding-top: 20px;
    transition: $transition-default;
    width: 100%;
    z-index: 100;
    display: none;
    flex: 1;
    overflow: auto;

    &--menuExpanded {
        display: block;
    }

    &--active {
        right: 0;
    }

    @include media-breakpoint-up(xl) {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        padding-top: 0;
        right: auto;
        left: 0;
        width: map-get($sidebar-width, "xl");
    }

    @include media-breakpoint-up(xxl) {
        width: map-get($sidebar-width, "xxl");
    }
}

.phonenumber {
    padding-left: 25px;
    padding-bottom: 20px;
    color: #c9c9c9;
    font-weight: bold;
}
