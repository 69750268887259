.page-header {
    background-color: map-deep-get($colors, "blue", "dark");
    margin-bottom: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    z-index: 80;

    @include media-breakpoint-up(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &__icon {
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        vertical-align: text-bottom;

        svg {
            fill: map-deep-get($colors, "white", "default");
            width: 100%;
            height: 100%;
        }
    }

    &__type {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "white", "default");
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 0;
    }

    &__subtitle {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "white", "default");
        // font-family: $headings-font-family;
        font-weight: 500;
        font-size: rem-calc(12);
        letter-spacing: 0.005em;
        margin: 0.75em 0 0;
    }

    &__back-to-overview {
        margin-top: 2em;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        text-decoration: underline;
        color: map-deep-get($colors, "white", "default");
        text-transform: uppercase;
        font-family: $headings-font-family;
        font-weight: 800;
        font-size: rem-calc(12);
        letter-spacing: 0.018em;

        @include media-breakpoint-down(sm) {
            margin-top: 1px;
        }
    }
}