.messages-block {
    padding: 0 25px;
    position: relative;
    color: map-deep-get($colors, "black", "default");

    &__item {
        padding-bottom: 25px;
        padding-top: 25px;

        &:not(:last-child) {
            border-bottom: 1px solid map-deep-get($colors, "gray", "default");
        }
    }

    &__author {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }

    &__author-image-wrapper {
        border-radius: 50%;
        display: inline-block;
        height: 35px;
        overflow: hidden;
        width: 35px;
    }

    &__author-image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__author-inner {
        align-items: center;
        display: inline-flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        padding-left: #{$grid-gutter-width / 2};
    }

    &__author-name {
        flex: 1 1 100%;
    }

    &__author-function {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "gray", "dark");
        flex: 1 1 100%;
        font-weight: 800;
    }

    &__date {
        color: map-deep-get($colors, "gray", "dark");
        font-size: 0.67em;
        margin-bottom: .25em;
        margin-top: .5em;
    }

    &__status {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__content {
        flex: 1 1 100%;
        font-family: $headings-font-family;
        font-weight: 800;
        letter-spacing: 0.019em;
        margin-bottom: 0.25em;
        margin-top: 0.5em;
    }

    &__link {
        margin-top: 1em;
        flex: 1 1 100%;
    }
}
