.client-info {
    &__row {
        margin-bottom: 1em;
    }

    &__label {
        display: inline-block;
        font-weight: 800;
    }

    &__value {
        display: inline-block;
    }

    &__link {
        display: block;
        margin-bottom: #{$grid-gutter-width * 2};
    }
}