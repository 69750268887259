.declaration-receipt-item {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    max-height: 200px;
    transition: all ease-in-out 0s;
    font-size: rem-calc(14);

    &:last-child {
        border-bottom: none;
    }

    &__type {
        font-family: $headings-font-family;
        font-weight: 600;
    }

    &__distance {
        font-family: $headings-font-family;
        font-weight: 600;
    }

    &__cost-type {
        color: map-deep-get($colors, "gray", "default");
        font-weight: 400;
        font-size: rem-calc(14);
        -webkit-font-smoothing: antialiased;
    }

    &__date {
        color: map-deep-get($colors, "gray", "default");
        font-weight: 400;
        font-size: rem-calc(14);
        -webkit-font-smoothing: antialiased;
    }

    &__controls {
        margin-top: 10px;
    }

    &__edit {
        color: map-deep-get($colors, "blue", "dark");
        display: block;
        font-size: rem-calc(14);
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    &__delete {
        color: map-deep-get($colors, "validation", "invalid");
        display: block;
        font-size: rem-calc(14);
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    &--removed {
        transition: all ease-in-out 0.35s;
        opacity: 0;
        max-height: 0;
        margin: 0;
        padding: 0;
    }
}