.declaration-receipt {
    position: sticky;
    top: 80px;
    left: 0;

    &__items {
        margin-bottom: 20px;
    }

    &__total {
        font-size: rem-calc(24);
        line-height: 1.2;
        letter-spacing: 0.019em;
        font-weight: 100;
    }

    &__summary {
        margin: 30px -20px -20px;
        background: map-deep-get($colors, "brand", "primary-light");
        padding: 20px;
    }

    &__explanation {
        color: map-deep-get($colors, "gray", "darker");
    }

    &__submit {
        margin: 30px 0;
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    &__clear {
        color: map-deep-get($colors, "validation", "invalid");
        display: block;
        font-size: rem-calc(14);
        cursor: pointer;
    }
}