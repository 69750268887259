.block {
    background-color: map-deep-get($colors, "white", "default");
    border: 1px solid map-deep-get($colors, "gray", "default");
    border-radius: $border-radius;
    display: block;
    margin-bottom: $grid-gutter-width;
    padding: 20px;
    transition: background $transition-default-time $transition-default-smoothing color $transition-default-time $transition-default-smoothing;
    width: 100%;

    &--mt {
        margin-top: $grid-gutter-width;
    }

    &__title {
        margin-bottom: 1em;

        color: map-deep-get($colors, "brand", "secondary");

        /* stylelint-disable */
        .declaration__gift {
            text-align: right;
            font-size: .8rem;
            float: right;
        }
        /* stylelint-enable */
    }

    &__image {
        margin-bottom: 1em;
        width: 100%;
    }

    &__title-separator {
        margin-right: 1em;
    }

    &__heading {
        margin-bottom: 0.5em;
    }

    &__content {
        /* stylelint-disable */
        p {
            margin-top: 0;
        }
        /* stylelint-enable */
    }

    &--link {
        cursor: pointer;

        &:hover {
            background-color: map-deep-get($colors, "brand", "primary-light");

            /* stylelint-disable */
            .text-link {
                color: darken(map-deep-get($colors, "blue", "dark"), 10%);

                &::before {
                    margin-left: 0.3em;
                    margin-right: 0.2em;
                }
            }
            /* stylelint-enable */
        }
    }

    &--selected {
        cursor: default;
        border: 1px solid map-deep-get($colors, "blue", "dark");
        background-color: map-deep-get($colors, "brand", "primary-light");
    }

    &--expanded:hover {
        background-color: map-deep-get($colors, "white", "default");
    }

    &--info {
        background: map-deep-get($colors, "brand", "primary-light");

        /* stylelint-disable */
        p {
            margin-top: 0;
        }

        h1,h2,h3,h4,h5,h6 {
            &:not(:first-child) {
                margin-top: 2em;
            }
        }
        /* stylelint-enable */
    }

    &--mb-40 {
        margin-bottom: #{$grid-gutter-width * 2};
    }

    &__label {
        color: map-deep-get($colors, "gray", "darker");
        margin-bottom: .5rem;
    }
}
