.blocks {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary");
        margin-bottom: 1em;

        &--with-filter {
            margin-top: rem-calc(11);
        }
    }

    &__icon {
        height: 1.25rem;
        width: 1.25rem;

        svg {
            width: 100%;
            height: 100%;
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &--no-mt {
        margin-top: 0;
    }

    &--no-mb {
        margin-bottom: 0;
    }

    &--slider {
        &::after {
            content: "flickity";
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;

            &::after {
                content: none;
            }
        }
    }
}
