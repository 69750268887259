.chat-window {
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    transition: $transition-default;
    margin: 0 -20px;
    padding: 0 20px;

    &--expanded {
        border-top: 1px solid map-deep-get($colors, "gray", "default");
        margin: 20px -20px 0;
        max-height: 50vh;
        padding: 20px 20px 0;
    }

    &__intro {
        color: map-deep-get($colors, "gray", "darker");
        font-size: rem-calc(12);
        font-family: $headings-font-family;
        font-weight: 600;
        letter-spacing: 0.019em;
        text-transform: uppercase;
        margin: 1em 0 2em;
    }

    &__contact {
        color: map-deep-get($colors, "black", "default");
    }
}