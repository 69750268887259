.report-client {
    color: map-deep-get($colors, "black", "default");
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    $elm: &;

    &__radio:checked + #{$elm} {
        border-color: map-deep-get($colors, "blue", "dark");
        background: map-deep-get($colors, "brand", "primary-light");
    }

    &__block {
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        width: 100%;
        flex: 1 1 100%;

        @include media-breakpoint-up(md) {
            width: 25%;
            flex: 1 1 25%;
        }

        &--client {
            #{$elm}__value {
                font-family: $headings-font-family;
                font-weight: 800;
            }
        }
    }

    &__label {
        color: map-deep-get($colors, "gray", "default");
        font-size: rem-calc(12);
        letter-spacing: 0.018em;
        font-family: $headings-font-family;

        @include media-breakpoint-up(md) {
            margin-bottom: 0.5em;
        }
    }

    &__value {
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(16);
        letter-spacing: 0.018em;
        font-weight: 400;
        margin-bottom: 0.5em;
        display: inline-block;

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
        }
    }
}