.block-top {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all $transition-default-time 0.2s $transition-default-smoothing;
    $elm: &;

    &--expanded {
        margin-top: 20px;
        max-height: 200px;
        opacity: 1;

        @include media-breakpoint-up(md) {
            margin-bottom: 10px;
        }

        #{$elm}__expand-icon {
            transform: rotate(180deg);
        }
    }

    &__status {
        display: flex;
        align-items: center;
    }
}