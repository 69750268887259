.login {
    &__password-reset {
        height: 40px;
        display: flex;
        align-items: center;
    }

    &__bottom {
        margin-top: 40px;
    }

    &__two-factor-input {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
    }
}