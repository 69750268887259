.notification-box {
    border: 1px solid map-deep-get($colors, "gray", "default");
    background-color: map-deep-get($colors, "white", "default");
    border-radius: $border-radius;
    margin-bottom: $grid-gutter-width;
    padding: $grid-gutter-width;
    $elm: &;

    @include media-breakpoint-up("sm") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__text {
        font-size: 0.9rem;
    }

    &__button {
        flex-shrink: 0;
    }

    &--with-button {
        #{$elm}__text {
            padding-bottom: $grid-gutter-width;

            @include media-breakpoint-up("sm") {
                padding-right: $grid-gutter-width;
                padding-bottom: 0;
            }
        }
    }
}
