.user-menu {
    display: inline-flex;
    margin-left: -5px;
    flex: 1 1 100%;
    $elm: &;

    @include media-breakpoint-up(xl) {
        flex: 0 0 auto;
    }

    &__list {
        display: flex;
        position: relative;

        &--sub {
            min-width: 100%;
            background: map-deep-get($colors, "blue", "dark");
            display: none;
            border-bottom: 0.5px solid map-deep-get($colors, "gray", "default");
            padding-bottom: 2em;
            margin-left: 30px;

            @include media-breakpoint-up(xl) {
                margin-bottom: 0;
                position: absolute;
                top: 100%;
                right: 0;
                padding: 10px;
            }
        }
    }

    &__icon {
        transition: $transition-default;
        fill: map-deep-get($colors, "white", "default");
        display: inline-flex;
        width: 0.8em;
        height: 0.8em;
        margin-left: 0.25em;
        vertical-align: middle;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__list--sub #{$elm}__item {
        display: block;
        line-height: 1.2em;
        padding: 2em 0 0;
        white-space: nowrap;
    }

    &__item {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "white", "default");
        cursor: pointer;
        display: inline-block;
        padding: #{$grid-gutter-width / 2};
        font-family: $headings-font-family;
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: 0.005em;
        font-weight: 800;
        border: 0.5px solid transparent;
        border-bottom: 0;

        &--open {
            #{$elm}__icon {
                transform: rotateX(180deg);
            }

            #{$elm}__list {
                display: block;
            }
        }

        @media (pointer: fine) and (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                border-color: map-deep-get($colors, "gray", "default");

                #{$elm}__icon {
                    transform: rotateX(180deg);
                }

                #{$elm}__list {
                    display: block;
                    border: 0.5px solid map-deep-get($colors, "gray", "default");
                    border-top: 0;
                }

                #{$elm}__item {
                    border: 0;
                }
            }
        }
    }

    &__link {
        -webkit-font-smoothing: antialiased;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        transition: $transition-default;

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__avatar {
        $font-size: 18;
        $circle: #{(35 / (16 * ($font-size / 16))) * 1em};
        background: map-deep-get($colors, "brand", "primary");
        border-radius: 100%;
        color: map-deep-get($colors, "white", "default");
        display: inline-block;
        font-size: rem-calc($font-size);
        height: $circle;
        line-height: $circle;
        text-align: center;
        vertical-align: middle;
        width: $circle;
        overflow: hidden;
        margin-right: 5px;

        &--splash {
            width: 120px;
            height: 120px;
            line-height: 120px;
            font-size: rem-calc(70);
        }
    }

    &__photo {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}
