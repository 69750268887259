.chat-message {
    &__date {
        font-family: $headings-font-family;
        line-height: 1.25em;
        font-size: rem-calc(12);
        color: map-deep-get($colors, "gray", "default");
        font-weight: 600;
        margin-bottom: 10px;
        display: inline-block;
    }

    &__content {
        padding: 10px 15px;
        border-radius: 3px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding: 20px 22px;
        }

        &--received {
            background: map-deep-get($colors, "chat", "received");
        }

        &--send {
            background: map-deep-get($colors, "chat", "send");
        }
    }

    &__avatar-wrapper {
        $circle: 45px;
        $circle-lg: 65px;
        $font-size: 18;
        $font-size-lg: 24;
        display: inline-block;
        height: $circle;
        width: $circle;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 8px 24px rgba(map-deep-get($colors, "blue", "default"), 0.7);
        background: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc($font-size);
        line-height: $circle;
        text-align: center;
        vertical-align: middle;

        @include media-breakpoint-up(lg) {
            height: $circle-lg;
            width: $circle-lg;
            line-height: $circle-lg;
            font-size: rem-calc($font-size-lg);
        }
    }

    &__avatar {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}