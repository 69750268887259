.dataset-error {
    text-align: center;
    border-radius: $border-radius;
    background-color: map-deep-get($colors, "white", "default");
    border: 1px solid map-deep-get($colors, "gray", "default");
    padding: $grid-gutter-width;

    &__icon {
        margin-bottom: $grid-gutter-width;
        width: 50px;
        height: 50px;
        display: inline-block;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        line-height: 1.25em;
        letter-spacing: 0.018em;
        font-size: rem-calc(12);
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: $grid-gutter-width;
    }
}
