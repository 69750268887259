.account-strength {
    background-image: linear-gradient(to right, rgba(map-deep-get($colors, "blue", "dark"), 1), rgba(map-deep-get($colors, "blue", "default"), 1));
    //background-image: linear-gradient(to right, rgba(255, 0, 0, 100), rgba(0, 255, 0, 1));
    height: 20px;
    width: 100%;
    min-width: 250px;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &__cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: map-deep-get($colors, "white", "default");
        width: 100%;
        transition: width $transition-default-smoothing $transition-default-time $transition-default-time;
        // om redering bij sticky goed te houden
        transform: translate3d(0, 0, 0);
    }

    &__label {
        margin-bottom: 1em;
        display: block;
    }

    &__title {
        display: inline-block;
        margin-right: 3em;
    }

    &__description {
        display: inline-block;
        color: map-deep-get($colors, "gray", "default");
    }
}