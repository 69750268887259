.chat-input {
    display: none;

    &--expanded {
        display: block;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid map-deep-get($colors, "gray", "default");

        @include media-breakpoint-up(md) {
            margin-top: 20px;
            padding-top: 20px;
        }
    }

    &__textarea {
        min-height: 100px;
        font-size: rem-calc(16);

        $textarea: &;

        @at-root .form__group #{$textarea} {
            height: auto;
        }

        &--disabled {
            background-color: map-deep-get($colors, "gray", "light");
        }
    }

    &__button {
        &--disabled {
            &, &:hover {
                background-color: map-deep-get($colors, "gray", "light");
                color: map-deep-get($colors, "gray", "darker");
                cursor: default;
            }
        }
    }
}
