@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700");
.container,
.container-fluid,
.container-lg,
.container-xxl {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1120px; } }

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 25; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; } }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11;
  display: flex;
  align-items: center; }
  .hamburger--inverted .hamburger-inner {
    background-color: #000000; }
    .hamburger--inverted .hamburger-inner::after, .hamburger--inverted .hamburger-inner::before {
      background-color: #000000; }
  .hamburger__label {
    color: #ffffff;
    display: inline-block;
    margin-right: 0.5em;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-family: Rucksack; }

.b-lazy {
  opacity: 0;
  transition: all 0.25s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-viewport {
  transition: height .3s ease-in-out; }

.flickity-page-dots {
  text-align: right; }
  .flickity-page-dots .dot {
    margin: 0 3px;
    background-color: #a4a4a4; }
    .flickity-page-dots .dot.is-selected {
      background: #00C389; }

.avatar-cropper .avatar-cropper-overlay {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.avatar-cropper .avatar-cropper-img-input {
  display: none; }

.avatar-cropper .avatar-cropper-close {
  display: none; }

.avatar-cropper .avatar-cropper-container {
  z-index: 999; }
  .avatar-cropper .avatar-cropper-container .avatar-cropper-image-container {
    background: #fff;
    box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);
    position: relative;
    max-width: 400px;
    height: 300px; }
  .avatar-cropper .avatar-cropper-container img {
    max-width: 100%;
    height: 100%; }

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  user-select: none; }
  .cropper-container img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%; }

.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.cropper-wrap-box, .cropper-canvas {
  overflow: hidden; }

.cropper-drag-box {
  background-color: #fff;
  opacity: 0; }

.cropper-modal {
  background-color: #000;
  opacity: 0.5; }

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
  overflow: hidden;
  width: 100%; }

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute; }
  .cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%; }
  .cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3); }

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0; }
  .cropper-center::before, .cropper-center::after {
    background-color: #eee;
    content: ' ';
    display: block;
    position: absolute; }
  .cropper-center::before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px; }
  .cropper-center::after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px; }

.cropper-face, .cropper-line, .cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%; }

.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0; }

.cropper-line {
  background-color: #39f; }
  .cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px; }
  .cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px; }
  .cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px; }
  .cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0; }

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px; }
  .cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%; }
  .cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px; }
  .cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%; }
  .cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px; }
  .cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px; }
  .cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px; }
  .cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px; }
  .cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px; }
    @media (min-width: 768px) {
      .cropper-point.point-se {
        height: 15px;
        width: 15px; } }
    @media (min-width: 992px) {
      .cropper-point.point-se {
        height: 10px;
        width: 10px; } }
    @media (min-width: 1200px) {
      .cropper-point.point-se {
        height: 5px;
        opacity: 0.75;
        width: 5px; } }
  .cropper-point.point-se::before {
    background-color: #39f;
    bottom: -50%;
    content: ' ';
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%; }

.cropper-invisible {
  opacity: 0; }

.cropper-bg {
  background-image: url("../images/bg.png"); }

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0; }

.cropper-hidden {
  display: none !important; }

.cropper-move {
  cursor: move; }

.cropper-crop {
  cursor: crosshair; }

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  display: inline-block;
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeTr-enter-active {
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.fadeTr-leave-active {
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out; }

.fadeTr-enter, .fadeTr-leave-to {
  opacity: 0; }

.slide-enter-active,
.slide-leave-active {
  -webkit-transition: all 1s;
  transition: all 1s; }

.slide-enter,
.slide-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
  transform: translateX(30px); }

.dg-backdrop-enter-active {
  -webkit-animation: dg-fadeIn .3s;
  animation: dg-fadeIn .3s; }

.dg-backdrop-leave-active {
  -webkit-animation: dg-fadeOut .5s;
  animation: dg-fadeOut .5s; }

.dg-fade-enter-active {
  -webkit-animation: dg-fadeIn 0.6s cubic-bezier(0, 0, 0.58, 1);
  animation: dg-fadeIn 0.6s cubic-bezier(0, 0, 0.58, 1); }

.dg-fade-leave-active {
  -webkit-animation: dg-fadeOut 0.6s cubic-bezier(0, 0, 0.58, 1);
  animation: dg-fadeOut 0.6s cubic-bezier(0, 0, 0.58, 1); }

@-webkit-keyframes dg-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes dg-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes dg-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes dg-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.dg-zoom-enter-active {
  -webkit-animation: dg-zoomIn 0.3s cubic-bezier(0, 0, 0.58, 1);
  animation: dg-zoomIn 0.3s cubic-bezier(0, 0, 0.58, 1); }

.dg-zoom-leave-active {
  -webkit-animation: dg-zoomOut 0.4s cubic-bezier(0, 0, 0.58, 1);
  animation: dg-zoomOut 0.4s cubic-bezier(0, 0, 0.58, 1); }

@-webkit-keyframes dg-zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes dg-zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes dg-zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes dg-zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.dg-bounce-enter-active {
  -webkit-animation: dg-bounceIn .6s;
  animation: dg-bounceIn .6s; }

.dg-bounce-leave-active {
  -webkit-animation: dg-zoomOut .6s;
  animation: dg-zoomOut .6s; }

@-webkit-keyframes dg-bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  40% {
    opacity: 1;
    -webkit-transform: scale(1.06);
    transform: scale(1.06); }
  60% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes dg-bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  40% {
    opacity: 1;
    -webkit-transform: scale(1.06);
    transform: scale(1.06); }
  60% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes dg-bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

@keyframes dg-bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

.dg-btn-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0; }

.dg-btn-loader .dg-circles {
  width: 100%;
  display: block;
  text-align: center; }

.dg-btn-loader .dg-circle {
  width: .9em;
  height: .9em;
  opacity: 0;
  background-color: #09a2e3;
  display: inline-block;
  border-radius: 50%;
  -webkit-animation-name: dg-circle-oscillation;
  animation-name: dg-circle-oscillation;
  -webkit-animation-duration: 0.5875s;
  animation-duration: 0.5875s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal; }

.dg-btn-loader .dg-circle:not(:last-child) {
  margin-right: 8px; }

.dg-btn-loader .dg-circle:nth-child(1) {
  -webkit-animation-delay: 0.1195s;
  animation-delay: 0.1195s; }

.dg-btn-loader .dg-circle:nth-child(2) {
  -webkit-animation-delay: 0.2755s;
  animation-delay: 0.2755s; }

.dg-btn-loader .dg-circle:nth-child(3) {
  -webkit-animation-delay: 0.3485s;
  animation-delay: 0.3485s; }

@-webkit-keyframes dg-circle-oscillation {
  0% { }
  50% {
    opacity: 1; }
  100% { } }

@keyframes dg-circle-oscillation {
  0% { }
  50% {
    opacity: 1; }
  100% { } }

body.dg-open {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.dg-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.dg-container [disabled] {
  cursor: not-allowed;
  opacity: .3; }

.dg-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000; }

.dg-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000; }

.dg-content-cont {
  width: 100%;
  font-family: inherit; }

.dg-main-content {
  width: 98%;
  /*width: calc(98% - 30px);*/
  max-width: 400px;
  padding: 15px;
  border-radius: 5px;
  margin: 25px auto;
  background-color: #ffffff; }

.dg-content {
  font-size: 16px;
  line-height: 1.3em; }

.dg-title {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 18px; }

.dg-content-body {
  border-bottom: 2px solid #E1E6EA;
  padding-bottom: 15px; }

.dg-content-footer {
  position: relative;
  padding: 15px 0 0; }

.dg-form {
  background-color: ghostwhite;
  padding: 10px;
  margin-bottom: -15px; }

.dg-content-cont--floating {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  margin-top: 0; }

@media all and (max-height: 700px) {
  .dg-content-cont--floating {
    position: relative;
    top: 10%;
    -webkit-transform: none;
    transform: none;
    margin-top: 0; } }

.dg-btn {
  display: inline-block;
  position: relative;
  min-width: 80px;
  padding: 6px 20px;
  border-radius: 4px;
  outline: 0;
  border: 2px solid transparent;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  font-weight: 700; }

.dg-btn:focus,
.dg-btn:active,
.dg-btn:link {
  outline: none; }

.dg-btn::-moz-focus-inner {
  border: 0; }

.dg-btn--cancel {
  color: #fefefe;
  background-color: #0096D9; }

.dg-btn--ok {
  color: #0096D9;
  background-color: #fefefe;
  border-color: #0096D9; }

.dg-pull-right {
  float: right; }

.dg-btn.dg-btn--loading .dg-btn-content {
  visibility: hidden; }

.dg-clear:before {
  content: ' ';
  display: block;
  clear: both; }

.dg-content-body--has-title .dg-content {
  font-size: 14px; }

.dg-container--has-input .dg-main-content {
  max-width: 450px; }

.dg-container--has-input .dg-content {
  margin-bottom: 15px; }

.dg-container--has-input .dg-content-body {
  border-bottom: none; }

.dg-container--has-input .dg-form {
  border: 1px solid #E1E6EA;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.dg-container--has-input .dg-content-footer {
  background-color: ghostwhite;
  border: 1px solid #E1E6EA;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 10px 10px; }

.dg-container .dg-highlight-1 {
  color: #FF8C00;
  font-weight: bold;
  border-bottom: 1px solid #2ba5ff; }

.dg-container .dg-highlight-2 {
  color: #2ba5ff;
  font-weight: bold;
  border-bottom: 1px solid #FF8C00; }

.dg-btn {
  text-align: center;
  flex-direction: column; }

.dg-btn-loader .dg-circle {
  background-color: #ffffff; }

*, *::before, *::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  margin: 0 auto;
  padding: 0;
  font-family: Lato, "Arial", sans-serif;
  height: 100%; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

b, strong {
  font-weight: 700; }

::selection {
  background-color: #00C389;
  color: #000000; }

.h-100 {
  height: 100%; }

/* stylelint-disable */
fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

/* stylelint-enable */
/* stylelint-disable */
.row-custom-gutter {
  margin-left: -2.5px;
  margin-right: -2.5px; }
  .row-custom-gutter .col, .row-custom-gutter div[class^="col-"] {
    padding-left: 2.5px;
    padding-right: 2.5px; }
  @media (min-width: 992px) {
    .row-custom-gutter {
      margin-left: -10px;
      margin-right: -10px; }
      .row-custom-gutter .col, .row-custom-gutter div[class^="col-"] {
        padding-left: 10px;
        padding-right: 10px; } }

@media (min-width: 768px) {
  .container {
    max-width: 70vw; } }

@media (min-width: 992px) {
  .container {
    max-width: 75vw; } }

/* stylelint-enable */
.text-center {
  text-align: center; }

@font-face {
  font-family: gzn;
  src: url("../fonts/gzn.eot");
  src: url("../fonts/gzn.eot?#iefix") format("embedded-opentype"), url("../fonts/gzn.woff") format("woff"), url("../fonts/gzn.ttf") format("truetype"), url("../fonts/gzn.svg#gzn") format("svg");
  font-weight: 400;
  font-style: normal; }

[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: gzn;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-angle-down::before {
  content: "\61"; }

.icon-angle-left::before {
  content: "\62"; }

.icon-angle-right::before {
  content: "\63"; }

.icon-angle-up::before {
  content: "\64"; }

.icon-cross::before {
  content: "\65"; }

@font-face {
  font-family: RuckSack;
  src: url("../fonts/RuckSack-Light.eot");
  src: local("RuckSack Light"), local("RuckSack-Light"), url("../fonts/RuckSack-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/RuckSack-Light.woff2") format("woff2"), url("../fonts/RuckSack-Light.woff") format("woff"), url("../fonts/RuckSack-Light.ttf") format("truetype"), url("../fonts/RuckSack-Light.svg#RuckSack-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: RuckSack;
  src: url("../fonts/RuckSack-Book.eot");
  src: local("RuckSack-Book"), url("../fonts/RuckSack-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/RuckSack-Book.woff2") format("woff2"), url("../fonts/RuckSack-Book.woff") format("woff"), url("../fonts/RuckSack-Book.ttf") format("truetype"), url("../fonts/RuckSack-Book.svg#RuckSack-Book") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: RuckSack;
  src: url("../fonts/RuckSack-Medium.eot");
  src: local("RuckSack-Medium"), url("../fonts/RuckSack-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/RuckSack-Medium.woff2") format("woff2"), url("../fonts/RuckSack-Medium.woff") format("woff"), url("../fonts/RuckSack-Medium.ttf") format("truetype"), url("../fonts/RuckSack-Medium.svg#RuckSack-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: RuckSack;
  src: url("../fonts/RuckSack-Demi.eot");
  src: local("RuckSack Demi"), local("RuckSack-Demi"), url("../fonts/RuckSack-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/RuckSack-Demi.woff2") format("woff2"), url("../fonts/RuckSack-Demi.woff") format("woff"), url("../fonts/RuckSack-Demi.ttf") format("truetype"), url("../fonts/RuckSack-Demi.svg#RuckSack-Demi") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: RuckSack;
  src: url("../fonts/RuckSack-Bold.eot");
  src: local("RuckSack Bold"), local("RuckSack-Bold"), url("../fonts/RuckSack-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/RuckSack-Bold.woff2") format("woff2"), url("../fonts/RuckSack-Bold.woff") format("woff"), url("../fonts/RuckSack-Bold.ttf") format("truetype"), url("../fonts/RuckSack-Bold.svg#RuckSack-Bold") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: RuckSack;
  src: url("../fonts/RuckSack-Black.eot");
  src: local("RuckSack-Black"), url("../fonts/RuckSack-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/RuckSack-Black.woff2") format("woff2"), url("../fonts/RuckSack-Black.woff") format("woff"), url("../fonts/RuckSack-Black.ttf") format("truetype"), url("../fonts/RuckSack-Black.svg#RuckSack-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Rucksack;
  margin: 0; }

h1, .h1 {
  font-size: 2.625rem;
  line-height: 1.2;
  letter-spacing: 0.019em; }

h2, .h2 {
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: 0.019em; }

h3, .h3 {
  font-size: 1.125rem;
  line-height: 1.2;
  letter-spacing: 0.019em; }

h4, .h4 {
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 0.016em; }

h5, .h5 {
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase; }

.button, .dg-btn {
  padding: 1.16em 1.22em;
  min-height: 2.8125rem;
  line-height: 1.25em;
  letter-spacing: 0.018em;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  background-color: #bebebe;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all 0.25s ease-in-out;
  font-weight: 800;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase; }
  .button svg, .dg-btn svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    vertical-align: middle; }
  .button--wide {
    width: 100%;
    justify-content: center; }
  .button--mt {
    margin-top: 20px; }
  .button--mb {
    margin-bottom: 20px; }
  .button--primary, .dg-btn--ok, .form__button {
    background-color: #00C389; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--primary:hover, .dg-btn--ok:hover, .form__button:hover {
        background-color: #009065; } }
  .button--white, .dg-btn--cancel {
    background: #ffffff;
    color: #00C389; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--white:hover, .dg-btn--cancel:hover {
        background: #f5f5f5; } }
  .button--darkblue {
    background-color: #00C389;
    color: #ffffff; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--darkblue:hover {
        background-color: #009065; } }
  .button__addition {
    display: block;
    font-size: 0.875rem;
    color: #bebebe;
    margin-top: 0.5em; }
  .button:disabled, .dg-btn:disabled {
    background: #c9c9c9;
    color: #a4a4a4;
    cursor: not-allowed; }

.text-link {
  border: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  color: #00C389;
  font-family: Rucksack;
  font-weight: 800;
  font-size: 0.75rem;
  letter-spacing: 0.018em;
  line-height: 1.2em;
  white-space: nowrap;
  outline: none;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out; }
  .text-link::before {
    content: "\63";
    display: inline-block;
    font-family: gzn;
    font-weight: 400;
    margin-right: 0.5em;
    text-transform: none;
    transition: all 0.25s ease-in-out;
    vertical-align: middle; }
  .text-link:hover {
    color: #009065; }
    .text-link:hover::before {
      margin-left: 0.3em;
      margin-right: 0.2em; }
  .text-link--white {
    color: #ffffff; }
    .text-link--white:hover {
      color: #ffffff; }
  .text-link--primary {
    color: #00C389; }
    .text-link--primary:hover {
      color: #00C389; }
  .text-link--delete {
    color: #ff7555; }
    .text-link--delete::before {
      content: "\65"; }
    .text-link--delete:hover {
      color: #ff4c22; }
      .text-link--delete:hover::before {
        margin-left: 0;
        margin-right: 0.5em; }

/* stylelint-disable */
.rich-text-area .intro {
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 1.75em; }

.rich-text-area p {
  margin: 0 0 1em; }

.rich-text-area h1, .rich-text-area h2, .rich-text-area h3, .rich-text-area h4, .rich-text-area h5, .rich-text-area h6 {
  margin: 1em 0 1em; }

.rich-text-area a:not(.btn) {
  text-decoration: underline;
  color: #00C389; }

/* stylelint-enable */
.barba-bg {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #f5f5f5;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.barba-wrapper {
  height: 100%; }

.barba-container {
  display: flex;
  flex-flow: column nowrap;
  height: 100%; }

/* stylelint-disable */
::-webkit-input-placeholder {
  color: #c9c9c9;
  font-style: italic;
  -webkit-font-smoothing: antialiased; }

::-moz-placeholder {
  color: #c9c9c9;
  font-style: italic;
  -webkit-font-smoothing: antialiased; }

:-ms-input-placeholder {
  color: #c9c9c9;
  font-style: italic;
  -webkit-font-smoothing: antialiased; }

:-moz-placeholder {
  color: #c9c9c9;
  font-style: italic;
  -webkit-font-smoothing: antialiased; }

/* stylelint-enable */
input, select, .form__file-input {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 0;
  color: #000000;
  font-family: Rucksack;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 800;
  height: 3.125rem;
  letter-spacing: 0.057em;
  line-height: 1.25;
  padding: 0 1rem;
  transition: all 0.25s ease-in-out;
  width: 100%; }
  input:focus, select:focus, .form__file-input:focus {
    outline: none;
    border: 1px solid #00C389; }

textarea, .textarea {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 0;
  color: #000000;
  font-family: Lato, "Arial", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.225px;
  line-height: 15px;
  min-height: 9.375rem;
  padding: 8px 16px;
  resize: vertical;
  transition: border .3s ease-in-out;
  width: 100%; }
  textarea:focus, .textarea:focus {
    border: 1px solid #00C389;
    outline: none; }

.textarea {
  display: block;
  margin-top: 5px; }

select {
  appearance: none;
  background: #ffffff;
  color: #000000;
  padding-right: 40px; }
  select::-ms-expand {
    display: none; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 3.125rem;
    color: #000000;
    border: 1px solid #c9c9c9;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select option {
      font-weight: 100; }
    .select-wrapper__select:focus {
      border: 1px solid #c9c9c9; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    pointer-events: none;
    width: 0.75rem;
    height: 0.6rem;
    margin-top: -0.3rem; }
    .select-wrapper__dropdown-icon svg {
      width: 100%;
      height: 100%;
      fill: #00C389;
      vertical-align: top; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer;
    flex-shrink: 0; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #ffffff;
      border: 0.7px solid #c9c9c9;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    background-color: #00C389;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked, .radio-wrapper input:checked {
      border: 1px solid #00C389; }
      .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
        opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.875rem;
    font-family: Rucksack;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
    color: #00C389; }
  .radio-wrapper--prevent-click label,
  .radio-wrapper--prevent-click .radio-wrapper__label,
  .radio-wrapper--prevent-click .radio-wrapper__input-wrapper {
    cursor: default; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 1.75rem; }
  .checkbox-wrapper__input-dot {
    width: 1.875rem;
    height: 1.875rem;
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 0.25rem;
    margin-right: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .checkbox-wrapper__input-dot svg {
      opacity: 0;
      fill: #00C389;
      width: 70%;
      height: 70%; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 0;
    font-size: 0;
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot svg, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot svg {
      opacity: 1; }
  .checkbox-wrapper .form__label {
    align-self: center; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    cursor: pointer;
    align-self: center;
    font-weight: 400;
    text-transform: none; }

.form {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form__file-input {
    position: relative; }
    .form__file-input .form__input {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0; }
  .form__file-value {
    bottom: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 1rem;
    left: 0;
    padding: 0 1rem;
    line-height: 3.125rem;
    overflow: hidden;
    white-space: nowrap;
    color: #00C389;
    font-family: Rucksack;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase; }
    .form__file-value--done {
      color: #000000;
      text-transform: none; }
    .form__file-value::after {
      bottom: 0;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
      width: 10%; }

.input-wrapper {
  position: relative; }

.input-delete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-left: 0;
  border-radius: 0;
  cursor: pointer; }
  .input-delete svg {
    width: 0.75rem;
    height: 0.75rem;
    fill: #ff7555; }

fieldset {
  position: relative; }

fieldset[disabled]::after {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3) url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="); }

.form-message {
  background-color: #bebebe;
  border-radius: 0.25rem;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #55b890; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #ff7555; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }

.form {
  transition: all 0.25s ease-in-out; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    margin-bottom: 1.5rem; }
    .form__group textarea {
      height: 40vh; }
    .form__group--row {
      padding-bottom: 1.5rem; }
      @media (min-width: 768px) {
        .form__group--row {
          padding-bottom: 0; } }
  .form__invalid-message {
    color: #ff7555;
    display: none;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__invalid-message[show] {
      display: block; }
  .form__label {
    font-weight: 700;
    display: block;
    margin-bottom: 0.5em; }
  .form--loading {
    opacity: .5; }

.form-group__delete {
  display: inline-block;
  margin-top: 1em; }
  @media (min-width: 768px) {
    .form-group__delete {
      margin-top: 0; } }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.textarea {
  font-size: .875rem;
  font-weight: normal;
  letter-spacing: .500px; }

.radio-wrapper--disabled .radio-wrapper__label, .radio-wrapper--disabled label {
  color: #c9c9c9; }

.radio-wrapper__addition {
  margin-left: 10px;
  font-size: 85%; }
  .radio-wrapper__addition--red {
    color: #ff7555; }

/* stylelint-disable */
[v-cloak] {
  display: none; }

/* stylelint-enable */
/* stylelint-disable */
.cc-btn {
  flex: none !important; }

.cc-revoke {
  display: none !important; }

/* stylelint-enable */
.body {
  background-color: #f5f5f5; }

.header {
  background: #00C389;
  height: 65px;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  width: 100%;
  z-index: 90; }
  .header--menuExpanded {
    height: auto;
    overflow: visible; }
  @media (min-width: 1200px) {
    .header {
      align-items: center;
      display: flex;
      left: 0;
      overflow: visible;
      padding-left: 175px;
      position: fixed;
      right: 0;
      top: 0; } }
  @media (min-width: 1200px) {
    .header {
      padding-left: 200px; } }
  @media (min-width: 1600px) {
    .header {
      padding-left: 220px; } }
  .header__container {
    max-width: none; }
    @media (min-width: 1200px) {
      .header__container {
        max-width: 75vw; } }
  .header__bottom {
    width: 100%;
    display: block;
    border-bottom: 0.5px solid #c9c9c9; }

.service-menu {
  display: inline-flex;
  margin-left: -10px;
  margin-right: -10px;
  flex: 1 1 100%; }
  @media (min-width: 1200px) {
    .service-menu {
      flex: 0 0 auto; } }
  .service-menu__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1200px) {
      .service-menu__list {
        width: auto; } }
  .service-menu__item {
    -webkit-font-smoothing: antialiased;
    color: #ffffff;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Rucksack;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.005em;
    font-weight: 800;
    position: relative;
    flex: 1 1 100%; }
    @media (min-width: 1200px) {
      .service-menu__item {
        flex: 0 0 auto; } }
  .service-menu__link {
    -webkit-font-smoothing: antialiased;
    color: inherit;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 10px 10px 10px 0;
    text-transform: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    padding: 10px 15px;
    position: relative;
    transition: all 0.25s ease-in-out; }
    @media (min-width: 1200px) {
      .service-menu__link {
        margin: 0;
        padding: 0; } }
    .service-menu__link:hover {
      color: #000000; }
  .service-menu__icon {
    fill: #ffffff;
    display: inline-flex;
    vertical-align: text-bottom;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em; }
    .service-menu__icon svg {
      width: 100%;
      height: 100%; }
  .service-menu__value {
    position: relative; }
  .service-menu__index {
    -webkit-font-smoothing: antialiased;
    height: 1.25em;
    min-width: 1.25em;
    line-height: 1.25em;
    border-radius: 50%;
    background: #ff7555;
    color: #ffffff;
    font-size: 0.75rem;
    position: absolute;
    bottom: 95%;
    right: -.75em;
    display: inline-block;
    text-align: center; }

.user-menu {
  display: inline-flex;
  margin-left: -5px;
  flex: 1 1 100%; }
  @media (min-width: 1200px) {
    .user-menu {
      flex: 0 0 auto; } }
  .user-menu__list {
    display: flex;
    position: relative; }
    .user-menu__list--sub {
      min-width: 100%;
      background: #00C389;
      display: none;
      border-bottom: 0.5px solid #c9c9c9;
      padding-bottom: 2em;
      margin-left: 30px; }
      @media (min-width: 1200px) {
        .user-menu__list--sub {
          margin-bottom: 0;
          position: absolute;
          top: 100%;
          right: 0;
          padding: 10px; } }
  .user-menu__icon {
    transition: all 0.25s ease-in-out;
    fill: #ffffff;
    display: inline-flex;
    width: 0.8em;
    height: 0.8em;
    margin-left: 0.25em;
    vertical-align: middle; }
    .user-menu__icon svg {
      width: 100%;
      height: 100%; }
  .user-menu__list--sub .user-menu__item {
    display: block;
    line-height: 1.2em;
    padding: 2em 0 0;
    white-space: nowrap; }
  .user-menu__item {
    -webkit-font-smoothing: antialiased;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    font-family: Rucksack;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.005em;
    font-weight: 800;
    border: 0.5px solid transparent;
    border-bottom: 0; }
    .user-menu__item--open .user-menu__icon {
      transform: rotateX(180deg); }
    .user-menu__item--open .user-menu__list {
      display: block; }
    @media (pointer: fine) and (min-width: 1200px) {
      .user-menu__item:hover {
        border-color: #c9c9c9; }
        .user-menu__item:hover .user-menu__icon {
          transform: rotateX(180deg); }
        .user-menu__item:hover .user-menu__list {
          display: block;
          border: 0.5px solid #c9c9c9;
          border-top: 0; }
        .user-menu__item:hover .user-menu__item {
          border: 0; } }
  .user-menu__link {
    -webkit-font-smoothing: antialiased;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    transition: all 0.25s ease-in-out; }
    .user-menu__link:hover {
      color: #000000; }
  .user-menu__avatar {
    background: #00C389;
    border-radius: 100%;
    color: #ffffff;
    display: inline-block;
    font-size: 1.125rem;
    height: 1.94444em;
    line-height: 1.94444em;
    text-align: center;
    vertical-align: middle;
    width: 1.94444em;
    overflow: hidden;
    margin-right: 5px; }
    .user-menu__avatar--splash {
      width: 120px;
      height: 120px;
      line-height: 120px;
      font-size: 4.375rem; }
  .user-menu__photo {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }

.logo {
  display: inline-block;
  height: 55px;
  width: 126px;
  margin: 5px 0; }
  @media (min-width: 1200px) {
    .logo {
      height: 67px;
      margin: 50px auto;
      width: 156px; } }
  .logo--auth {
    height: 98.78px;
    margin-top: 0;
    margin-bottom: 50px;
    margin-left: 0;
    width: 230px; }
  .logo svg {
    width: 100%;
    height: 100%;
    fill: #00C389; }
  .logo--white svg {
    fill: #ffffff; }
  .logo img {
    height: auto;
    max-width: 100%; }

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  max-height: 100%;
  display: flex;
  flex-flow: column; }
  @media (min-width: 1200px) {
    .navigation {
      max-height: none;
      position: relative;
      display: block; } }

.sidebar {
  background: #ffffff;
  padding-top: 20px;
  transition: all 0.25s ease-in-out;
  width: 100%;
  z-index: 100;
  display: none;
  flex: 1;
  overflow: auto; }
  .sidebar--menuExpanded {
    display: block; }
  .sidebar--active {
    right: 0; }
  @media (min-width: 1200px) {
    .sidebar {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      padding-top: 0;
      right: auto;
      left: 0;
      width: 200px; } }
  @media (min-width: 1600px) {
    .sidebar {
      width: 220px; } }

.phonenumber {
  padding-left: 25px;
  padding-bottom: 20px;
  color: #c9c9c9;
  font-weight: bold; }

.sidebar-nav {
  position: relative;
  padding-left: 20px; }
  .sidebar-nav__list {
    margin-bottom: 1em; }
  .sidebar-nav__indicator {
    left: 0;
    top: 0;
    height: 2.5rem;
    width: 0.25rem;
    background: #00C389;
    display: inline-block;
    position: absolute; }
    body.body--loaded .sidebar-nav__indicator {
      transition: all 0.25s ease-in-out; }
  .sidebar-nav__title {
    margin-bottom: 1em;
    margin-top: 2em;
    color: #c9c9c9; }
  .sidebar-nav__item {
    min-height: 2.5rem; }
  .sidebar-nav__link {
    color: #000000;
    font-family: Rucksack;
    font-size: 0.75rem;
    font-weight: 900;
    letter-spacing: 0.05em;
    line-height: 1.33em;
    padding: 0.66em 0;
    display: flex;
    text-transform: uppercase;
    align-items: center; }
  .sidebar-nav__icon {
    display: inline-flex;
    line-height: 1em;
    height: 2.2em;
    margin-right: .5em;
    width: 2.2em;
    align-items: center; }
    .sidebar-nav__icon svg {
      fill: #000000;
      height: 100%;
      max-height: 1.125rem;
      max-width: 1.5625rem;
      width: 100%; }
  .sidebar-nav__item:not(.sidebar-nav__item--active):hover .sidebar-nav__link {
    color: #00C389; }
  .sidebar-nav__item--active {
    /* stylelint-disable */
    /* stylelint-enable */ }
    .sidebar-nav__item--active .sidebar-nav__link {
      color: #00C389; }
    .sidebar-nav__item--active .sidebar-nav__icon svg {
      fill: #00C389; }
    .sidebar-nav__item--active #icon--meet-up .st0 {
      fill: #c9c9c9; }
    .sidebar-nav__item--active #icon--meet-up .st1 {
      fill: #40D2A6; }

.main {
  max-width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.25s ease-in-out;
  padding-top: 65px; }
  .main__inner {
    height: 100%; }
  @media (min-width: 1200px) {
    .main--menu-expanded {
      padding-left: 200px; } }
  @media (min-width: 1600px) {
    .main--menu-expanded {
      padding-left: 220px; } }

.page-header {
  background-color: #00C389;
  margin-bottom: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  z-index: 80; }
  @media (min-width: 768px) {
    .page-header {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .page-header__icon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: text-bottom; }
    .page-header__icon svg {
      fill: #ffffff;
      width: 100%;
      height: 100%; }
  .page-header__type {
    -webkit-font-smoothing: antialiased;
    color: #ffffff; }
  .page-header__title {
    color: #ffffff;
    margin-bottom: 0; }
  .page-header__subtitle {
    -webkit-font-smoothing: antialiased;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.005em;
    margin: 0.75em 0 0; }
  .page-header__back-to-overview {
    margin-top: 2em;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    text-decoration: underline;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Rucksack;
    font-weight: 800;
    font-size: 0.75rem;
    letter-spacing: 0.018em; }
    @media (max-width: 767.98px) {
      .page-header__back-to-overview {
        margin-top: 1px; } }

.footer {
  margin-top: 50px; }
  .footer__wrapper {
    border-top: 1px solid #c9c9c9;
    padding-bottom: 20px;
    padding-top: 20px; }

@media (min-width: 768px) {
  .footer-nav {
    margin-left: -6.66667px;
    margin-right: -6.66667px; } }

.footer-nav__item {
  text-align: center; }
  @media (min-width: 768px) {
    .footer-nav__item {
      display: inline-block;
      padding-left: 6.66667px;
      padding-right: 6.66667px; } }

.footer-nav__link {
  -webkit-font-smoothing: antialiased;
  color: #a4a4a4;
  font-weight: 400;
  text-decoration: underline;
  transition: all 0.25s ease-in-out; }
  .footer-nav__link:hover {
    color: #000000; }

.blocks {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .blocks {
      margin-bottom: 50px; } }
  .blocks__title {
    color: #672146;
    margin-bottom: 1em; }
    .blocks__title--with-filter {
      margin-top: 0.6875rem; }
  .blocks__icon {
    height: 1.25rem;
    width: 1.25rem; }
    .blocks__icon svg {
      width: 100%;
      height: 100%;
      fill: #672146; }
  .blocks--no-mt {
    margin-top: 0; }
  .blocks--no-mb {
    margin-bottom: 0; }
  .blocks--slider::after {
    content: "flickity";
    display: none; }
  @media (min-width: 992px) {
    .blocks--slider {
      display: flex;
      justify-content: flex-end; }
      .blocks--slider::after {
        content: none; } }

.block-header {
  display: block;
  cursor: pointer;
  margin: -20px;
  transition: all 0.25s ease-in-out;
  color: #000000; }
  @media (min-width: 992px) {
    .block-header {
      margin: -20px;
      padding: 20px; } }
  .block-header--expandable {
    cursor: pointer; }
  .block-header__top {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #a4a4a4;
    letter-spacing: 0.019em;
    font-weight: 700;
    font-family: Rucksack;
    margin: 0 -0.5em; }
  .block-header__top-value {
    display: inline-block;
    margin-bottom: .5em;
    padding-left: .5em;
    padding-right: .5em; }
  .block-header__top-icon {
    display: inline-block;
    padding-left: 0.5em;
    padding-right: 0.5em;
    vertical-align: middle; }
    .block-header__top-icon svg {
      width: 1.125rem;
      height: 1.125rem; }
    .block-header__top-icon svg path {
      fill: #00C389; }
  .block-header__expand-label {
    color: #a4a4a4;
    font-family: Rucksack;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase; }
  .block-header__expand-icon {
    transition: all 0.25s ease-in-out;
    align-items: center;
    background: #ffffff;
    border-radius: 50%;
    border: 0.705px solid #c9c9c9;
    display: inline-flex;
    height: 1.375rem;
    justify-content: center;
    width: 1.375rem; }
    .block-header__expand-icon--usd {
      transform: rotate(180deg); }
    .block-header__expand-icon--right {
      transform: rotate(-90deg); }
    .block-header__expand-icon svg {
      display: inline-block;
      fill: #00C389;
      height: 0.6875rem;
      width: 0.6875rem; }

.block-header-options__label {
  position: relative;
  color: #00C389;
  cursor: pointer; }
  .block-header-options__label svg {
    display: inline-block;
    vertical-align: middle; }
  .block-header-options__label svg path {
    fill: #00C389; }

.block-header-options__options-list {
  display: none;
  position: absolute;
  z-index: 9;
  right: 0;
  bottom: -5px;
  transform: translateY(100%);
  padding: 10px;
  border-radius: 0.25rem;
  background: #00C389;
  box-shadow: 0px 7px 24px rgba(0, 195, 137, 0.7);
  cursor: default; }
  .block-header-options__options-list--open {
    display: block; }

.block-header-options__option-link {
  white-space: nowrap;
  color: #ffffff; }
  .block-header-options__option-link:not(:last-child) {
    margin-bottom: 0.3125rem; }

.block {
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 0.25rem;
  display: block;
  margin-bottom: 20px;
  padding: 20px;
  transition: background 0.25s ease-in-out color 0.25s ease-in-out;
  width: 100%; }
  .block--mt {
    margin-top: 20px; }
  .block__title {
    margin-bottom: 1em;
    color: #672146;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .block__title .declaration__gift {
      text-align: right;
      font-size: .8rem;
      float: right; }
  .block__image {
    margin-bottom: 1em;
    width: 100%; }
  .block__title-separator {
    margin-right: 1em; }
  .block__heading {
    margin-bottom: 0.5em; }
  .block__content {
    /* stylelint-disable */
    /* stylelint-enable */ }
    .block__content p {
      margin-top: 0; }
  .block--link {
    cursor: pointer; }
    .block--link:hover {
      background-color: #E6F9F3;
      /* stylelint-disable */
      /* stylelint-enable */ }
      .block--link:hover .text-link {
        color: #009065; }
        .block--link:hover .text-link::before {
          margin-left: 0.3em;
          margin-right: 0.2em; }
  .block--selected {
    cursor: default;
    border: 1px solid #00C389;
    background-color: #E6F9F3; }
  .block--expanded:hover {
    background-color: #ffffff; }
  .block--info {
    background: #E6F9F3;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .block--info p {
      margin-top: 0; }
    .block--info h1:not(:first-child), .block--info h2:not(:first-child), .block--info h3:not(:first-child), .block--info h4:not(:first-child), .block--info h5:not(:first-child), .block--info h6:not(:first-child) {
      margin-top: 2em; }
  .block--mb-40 {
    margin-bottom: 40px; }
  .block__label {
    color: #a4a4a4;
    margin-bottom: .5rem; }

.blocks--slider {
  margin-bottom: 5px;
  flex-wrap: wrap;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .blocks--slider.flickity-enabled .block {
    margin-bottom: 0;
    min-height: 100%;
    margin-right: 10px;
    top: 0;
    width: calc(100% - 20px); }
  @media (min-width: 992px) {
    .blocks--slider.flickity-enabled {
      margin-right: 0;
      top: auto;
      width: 100%; } }

.case-file {
  color: #000000;
  display: flex;
  flex-wrap: wrap; }
  .case-file__block {
    color: #000000;
    display: inline-block; }
    .case-file__block--client {
      width: 100%;
      flex: 1 1 100%; }
      @media (min-width: 1200px) {
        .case-file__block--client {
          width: 25%;
          flex: 1 1 25%; } }
      .case-file__block--client .case-file__value {
        font-family: Rucksack;
        font-weight: 800; }
    .case-file__block--location {
      width: 100%;
      flex: 1 1 100%; }
      @media (min-width: 992px) {
        .case-file__block--location {
          width: 50%;
          flex: 1 1 50%; } }
      @media (min-width: 1200px) {
        .case-file__block--location {
          width: 25%;
          flex: 1 1 25%; } }
    .case-file__block--manager {
      width: 100%;
      flex: 1 1 100%; }
      @media (min-width: 992px) {
        .case-file__block--manager {
          width: 50%;
          flex: 1 1 50%; } }
      @media (min-width: 1200px) {
        .case-file__block--manager {
          width: 25%;
          flex: 1 1 25%; } }
    .case-file__block--link {
      margin-top: 1em;
      white-space: nowrap;
      flex: 1 1 auto;
      align-self: flex-end; }
      @media (min-width: 1200px) {
        .case-file__block--link {
          margin-top: 0; } }
  .case-file__label {
    color: #c9c9c9;
    font-size: 0.75rem;
    letter-spacing: 0.018em;
    font-family: Rucksack; }
    @media (min-width: 768px) {
      .case-file__label {
        margin-bottom: 0.5em; } }
  .case-file__value {
    color: #000000;
    font-size: 1rem;
    letter-spacing: 0.018em;
    font-weight: 400;
    margin-bottom: 0.5em;
    display: inline-block; }
    @media (min-width: 1200px) {
      .case-file__value {
        margin-bottom: 0; } }

.post__date {
  color: #c9c9c9; }

.post__author {
  color: #c9c9c9;
  font-size: 0.75rem; }

.post__title {
  color: #000000; }

.post__image {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px; }

.news-block {
  display: flex;
  flex-wrap: wrap;
  color: #000000; }
  .news-block__image-wrapper {
    display: inline-block;
    position: relative;
    max-width: 30%;
    flex: 1 1 30%; }
    @media (min-width: 768px) {
      .news-block__image-wrapper {
        max-width: 20%;
        flex: 1 1 20%; } }
    @media (min-width: 992px) {
      .news-block__image-wrapper {
        max-width: 30%;
        flex: 1 1 30%; } }
  .news-block__image {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: contain;
    object-position: top;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .news-block__content {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 70%;
    flex: 1 1 70%;
    padding-left: 10px; }
    @media (min-width: 768px) {
      .news-block__content {
        max-width: 80%;
        flex: 1 1 80%; } }
    @media (min-width: 992px) {
      .news-block__content {
        max-width: 70%;
        flex: 1 1 70%; } }
  .news-block__content-inner {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
    align-self: flex-start; }
  .news-block__date {
    margin-bottom: 0;
    color: #c9c9c9;
    flex: 1 1 100%; }
  .news-block__title {
    margin-top: 0;
    flex: 1 1 100%; }
  .news-block__bottom {
    align-self: flex-end;
    flex: 1 1 100%; }
  .news-block__author {
    color: #c9c9c9;
    font-weight: 400;
    font-size: 0.75rem; }
  @media (min-width: 768px) {
    .news-block--wide .news-block__image-wrapper {
      width: auto;
      flex: 1 1 120px;
      height: 70px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .news-block--wide .news-block__image-wrapper {
      height: auto;
      max-width: 20%;
      flex: 1 1 20%; } }
  @media (min-width: 768px) {
    .news-block--wide .news-block__content {
      flex: 1 1 auto;
      width: auto; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .news-block--wide .news-block__content {
      flex: 1 1 80%;
      max-width: 80%; } }
  .news-block--wide .news-block__link {
    flex: 1 0 100%;
    margin-top: 20px; }
    @media (min-width: 768px) {
      .news-block--wide .news-block__link {
        flex: 1 0 auto;
        align-self: center; } }

.account-aside {
  position: sticky;
  top: 80px;
  margin-bottom: 120px; }

.account-info__avatar {
  background: #00C389;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 5px;
  position: relative;
  width: 140px;
  height: 140px; }

.account-info__character {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 10vw; }
  @media (min-width: 1200px) {
    .account-info__character {
      font-size: 4vw; } }
  @media (min-width: 1600px) {
    .account-info__character {
      font-size: 3.125rem; } }

.account-info__photo {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%; }

.account-info__link--photo {
  margin-top: 1em;
  display: block;
  text-align: center; }

.account-info__list {
  margin-bottom: 1em; }

.account-info__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6); }
  .account-info__loading svg {
    width: 1.875rem;
    height: 1.875rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.9375rem;
    margin-top: -0.9375rem; }

.account-strength {
  background-image: linear-gradient(to right, #00c389, #40d2a6);
  height: 20px;
  width: 100%;
  min-width: 250px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  transform: translate3d(0, 0, 0); }
  .account-strength__cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    transition: width ease-in-out 0.25s 0.25s;
    transform: translate3d(0, 0, 0); }
  .account-strength__label {
    margin-bottom: 1em;
    display: block; }
  .account-strength__title {
    display: inline-block;
    margin-right: 3em; }
  .account-strength__description {
    display: inline-block;
    color: #c9c9c9; }

.block-top {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.25s 0.2s ease-in-out; }
  .block-top--expanded {
    margin-top: 20px;
    max-height: 200px;
    opacity: 1; }
    @media (min-width: 768px) {
      .block-top--expanded {
        margin-bottom: 10px; } }
    .block-top--expanded .block-top__expand-icon {
      transform: rotate(180deg); }
  .block-top__status {
    display: flex;
    align-items: center; }

.chat-window {
  max-height: 0;
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.25s ease-in-out;
  margin: 0 -20px;
  padding: 0 20px; }
  .chat-window--expanded {
    border-top: 1px solid #c9c9c9;
    margin: 20px -20px 0;
    max-height: 50vh;
    padding: 20px 20px 0; }
  .chat-window__intro {
    color: #a4a4a4;
    font-size: 0.75rem;
    font-family: Rucksack;
    font-weight: 600;
    letter-spacing: 0.019em;
    text-transform: uppercase;
    margin: 1em 0 2em; }
  .chat-window__contact {
    color: #000000; }

.chat-message__date {
  font-family: Rucksack;
  line-height: 1.25em;
  font-size: 0.75rem;
  color: #c9c9c9;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block; }

.chat-message__content {
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .chat-message__content {
      padding: 20px 22px; } }
  .chat-message__content--received {
    background: #dcebf7; }
  .chat-message__content--send {
    background: #dcf7e8; }

.chat-message__avatar-wrapper {
  display: inline-block;
  height: 45px;
  width: 45px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 8px 24px rgba(64, 210, 166, 0.7);
  background: #00C389;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 45px;
  text-align: center;
  vertical-align: middle; }
  @media (min-width: 992px) {
    .chat-message__avatar-wrapper {
      height: 65px;
      width: 65px;
      line-height: 65px;
      font-size: 1.5rem; } }

.chat-message__avatar {
  object-fit: cover;
  height: 100%;
  width: 100%; }

.chat-input {
  display: none; }
  .chat-input--expanded {
    display: block;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #c9c9c9; }
    @media (min-width: 768px) {
      .chat-input--expanded {
        margin-top: 20px;
        padding-top: 20px; } }
  .chat-input__textarea {
    min-height: 100px;
    font-size: 1rem; }
    .form__group .chat-input__textarea {
      height: auto; }
    .chat-input__textarea--disabled {
      background-color: #f5f5f5; }
  .chat-input__button--disabled, .chat-input__button--disabled:hover {
    background-color: #f5f5f5;
    color: #a4a4a4;
    cursor: default; }

.attachments-input__wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block; }

.attachments-input__element {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer; }

.attachments-item__name--error {
  color: #ff7555; }

.attachments-item__actions {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0.625rem;
  margin-top: -0.625rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.attachments-item__icon, .attachments-item__action {
  border: none;
  background: none transparent;
  padding: 0;
  margin: 0;
  display: inline-block; }
  .attachments-item__icon svg, .attachments-item__action svg {
    width: 100%;
    height: 100%; }
  .attachments-item__icon--delete, .attachments-item__action--delete {
    display: inline-block;
    height: 0.8rem;
    width: 0.8rem; }
    .attachments-item__icon--delete svg, .attachments-item__action--delete svg {
      fill: #ff7555; }

.attachments-item__action {
  cursor: pointer; }

.attachments-download {
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  letter-spacing: 0.018em; }
  .attachments-download__icon {
    width: 40px;
    height: 40px;
    margin-right: 1.25rem; }
  .attachments-download__filename {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: Rucksack; }

.report-step__divider {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 0;
  border-top: 1px solid #c9c9c9; }

.report-step__button {
  transition: all 0.25s 0.25s ease-in-out; }

.report-client {
  color: #000000;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap; }
  .report-client__radio:checked + .report-client {
    border-color: #00C389;
    background: #E6F9F3; }
  .report-client__block {
    color: #000000;
    display: inline-block;
    width: 100%;
    flex: 1 1 100%; }
    @media (min-width: 768px) {
      .report-client__block {
        width: 25%;
        flex: 1 1 25%; } }
    .report-client__block--client .report-client__value {
      font-family: Rucksack;
      font-weight: 800; }
  .report-client__label {
    color: #c9c9c9;
    font-size: 0.75rem;
    letter-spacing: 0.018em;
    font-family: Rucksack; }
    @media (min-width: 768px) {
      .report-client__label {
        margin-bottom: 0.5em; } }
  .report-client__value {
    color: #000000;
    font-size: 1rem;
    letter-spacing: 0.018em;
    font-weight: 400;
    margin-bottom: 0.5em;
    display: inline-block; }
    @media (min-width: 1200px) {
      .report-client__value {
        margin-bottom: 0; } }

.declaration-distance {
  position: relative; }
  .declaration-distance__value {
    opacity: 0; }
  .declaration-distance__value, .declaration-distance__input {
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    display: inline-block;
    width: auto;
    line-height: inherit;
    letter-spacing: inherit; }
  .declaration-distance__input {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 1;
    box-shadow: 0 2px 0 #000000; }
    .declaration-distance__input:focus {
      border: 0; }
  .declaration-distance__compensation {
    box-shadow: 0 2px 0 #000000; }
  .declaration-distance__notice {
    display: block;
    margin-top: 0.9375rem;
    font-size: 0.8rem; }
    .declaration-distance__notice--busy {
      color: #bebebe; }
    .declaration-distance__notice--error {
      color: #ff7555; }
      .declaration-distance__notice--error svg {
        fill: #ff7555; }
    .declaration-distance__notice--success {
      color: #55b890; }
      .declaration-distance__notice--success svg {
        fill: #55b890; }
    .declaration-distance__notice svg {
      height: 0.5625rem;
      width: 0.5625rem;
      margin-right: 0.3125rem; }

.declaration-block__date {
  color: #bebebe;
  display: inline-block;
  font-weight: 400;
  -webkit-font-smoothing: antialiased; }

.declaration-block__status {
  color: #000000;
  display: inline-block;
  font-weight: 400;
  margin-right: 0.5em;
  -webkit-font-smoothing: antialiased; }

.declaration-block__status-indicator {
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 1em; }
  .declaration-block__status-indicator--in-progress {
    background: #ff8600; }
  .declaration-block__status-indicator--in-concept {
    background: #4158f6; }
  .declaration-block__status-indicator--denied {
    background: #e50044; }

.declaration-block__value {
  color: #000000;
  display: inline-block;
  font-family: Rucksack;
  font-size: 0.875rem;
  font-weight: 600;
  margin-right: 2em;
  margin-top: 1em;
  text-transform: uppercase; }

.declaration-block__description {
  color: #000000;
  display: inline-block;
  font-family: Rucksack;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1em;
  text-transform: uppercase; }

.declaration-block__line {
  padding: 0.3125rem; }
  .declaration-block__line--striped {
    background-color: #f5f5f5; }
  .declaration-block__line-costs {
    display: block;
    text-align: right; }

.declaration-receipt {
  position: sticky;
  top: 80px;
  left: 0; }
  .declaration-receipt__items {
    margin-bottom: 20px; }
  .declaration-receipt__total {
    font-size: 1.5rem;
    line-height: 1.2;
    letter-spacing: 0.019em;
    font-weight: 100; }
  .declaration-receipt__summary {
    margin: 30px -20px -20px;
    background: #E6F9F3;
    padding: 20px; }
  .declaration-receipt__explanation {
    color: #a4a4a4; }
  .declaration-receipt__submit {
    margin: 30px 0;
    width: 100%;
    display: inline-block;
    text-align: center; }
  .declaration-receipt__clear {
    color: #ff7555;
    display: block;
    font-size: 0.875rem;
    cursor: pointer; }

.declaration-receipt-item {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c9c9c9;
  max-height: 200px;
  transition: all ease-in-out 0s;
  font-size: 0.875rem; }
  .declaration-receipt-item:last-child {
    border-bottom: none; }
  .declaration-receipt-item__type {
    font-family: Rucksack;
    font-weight: 600; }
  .declaration-receipt-item__distance {
    font-family: Rucksack;
    font-weight: 600; }
  .declaration-receipt-item__cost-type {
    color: #c9c9c9;
    font-weight: 400;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased; }
  .declaration-receipt-item__date {
    color: #c9c9c9;
    font-weight: 400;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased; }
  .declaration-receipt-item__controls {
    margin-top: 10px; }
  .declaration-receipt-item__edit {
    color: #00C389;
    display: block;
    font-size: 0.875rem;
    text-decoration: underline;
    cursor: pointer; }
    .declaration-receipt-item__edit:hover {
      text-decoration: none; }
  .declaration-receipt-item__delete {
    color: #ff7555;
    display: block;
    font-size: 0.875rem;
    text-decoration: underline;
    cursor: pointer; }
    .declaration-receipt-item__delete:hover {
      text-decoration: none; }
  .declaration-receipt-item--removed {
    transition: all ease-in-out 0.35s;
    opacity: 0;
    max-height: 0;
    margin: 0;
    padding: 0; }

.kids-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .kids-block__inner {
    flex: 1 0 auto; }
  .kids-block__title {
    color: #c9c9c9;
    margin-bottom: .5em;
    letter-spacing: .018em;
    font-family: Rucksack; }
  .kids-block__kid {
    color: #000000; }
  .kids-block__present {
    width: 40%; }
  .kids-block__link {
    flex: 0 0 100%;
    margin-top: 1em; }
    @media (min-width: 768px) {
      .kids-block__link {
        flex: 0 0 auto;
        margin-top: 0; } }

.promotion {
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .promotion__radio:checked + .promotion {
    background: #E6F9F3; }
  .promotion__content--top {
    display: flex;
    margin-bottom: 0.5em; }
  .promotion__content--bottom {
    margin-bottom: 0.5em; }
    @media (min-width: 768px) {
      .promotion__content--bottom {
        margin-bottom: 0; } }
  .promotion__content-item {
    margin-right: 1.25rem;
    letter-spacing: 0.018em; }
    .promotion__content-item--date {
      font-weight: 700; }
    .promotion__content-item--title {
      font-weight: 700; }
    .promotion__content-item--date, .promotion__content-item--time-start, .promotion__content-item--location {
      text-transform: uppercase;
      font-size: 0.75rem;
      font-family: Rucksack; }
  .promotion__text-link {
    width: 100%; }
    @media (min-width: 768px) {
      .promotion__text-link {
        width: auto;
        align-self: flex-end; } }

.promotion-note__title {
  margin-bottom: 0.3125rem;
  letter-spacing: 0.018em;
  font-family: Rucksack; }

.dataset-loader {
  text-align: center;
  border-radius: 0.25rem;
  background-color: #ffffff;
  padding: 20px; }
  .dataset-loader:not(.dataset-loader--noborder) {
    border: 1px solid #c9c9c9; }
  .dataset-loader__icon {
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    display: inline-block; }
    .dataset-loader__icon svg {
      fill: #672146;
      width: 100%;
      height: 100%; }
  .dataset-loader__text {
    line-height: 1.25em;
    letter-spacing: 0.018em;
    font-size: 0.75rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 20px; }
  .dataset-loader__progress {
    height: 20px;
    border: 1px solid #E6F9F3;
    background-color: #f5f5f5;
    text-align: left;
    border-radius: 0.25rem;
    overflow: hidden; }
  .dataset-loader__bar {
    height: 100%;
    background-color: #00C389;
    transition: all 0.5s;
    position: relative; }
    .dataset-loader__bar::after {
      content: " ";
      height: 110%;
      background-color: #00C389;
      position: absolute;
      right: -4px;
      top: -3px;
      width: 15px;
      transform: rotate(20deg); }

.dataset-error {
  text-align: center;
  border-radius: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  padding: 20px; }
  .dataset-error__icon {
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    display: inline-block; }
    .dataset-error__icon svg {
      width: 100%;
      height: 100%; }
  .dataset-error__text {
    line-height: 1.25em;
    letter-spacing: 0.018em;
    font-size: 0.75rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 20px; }

.promotion {
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .promotion__radio:checked + .promotion {
    background: #E6F9F3; }
  .promotion__content--top {
    display: flex;
    margin-bottom: 0.5em; }
  .promotion__content--bottom {
    margin-bottom: 0.5em; }
    @media (min-width: 768px) {
      .promotion__content--bottom {
        margin-bottom: 0; } }
  .promotion__content-item {
    margin-right: 1.25rem;
    letter-spacing: 0.018em; }
    .promotion__content-item--date {
      font-weight: 700; }
    .promotion__content-item--title {
      font-weight: 700; }
    .promotion__content-item--date, .promotion__content-item--time-start, .promotion__content-item--location {
      text-transform: uppercase;
      font-size: 0.75rem;
      font-family: Rucksack; }
  .promotion__text-link {
    width: 100%; }
    @media (min-width: 768px) {
      .promotion__text-link {
        width: auto;
        align-self: flex-end; } }

.carpool__header {
  margin-bottom: 0; }

.carpool__map {
  height: 100%;
  min-height: 800px;
  margin-left: -10px;
  margin-right: -10px; }

.carpool__sidebar {
  padding: 20px 0; }
  @media (min-width: 1200px) {
    .carpool__sidebar {
      padding: 50px 40px; } }

.carpool-legend {
  display: none;
  padding: 1rem;
  margin-right: 10px;
  border-radius: 0.25rem;
  box-shadow: 0 8px 24px rgba(64, 210, 166, 0.7);
  border: 1px solid #c9c9c9;
  background-color: #ffffff; }
  .carpool-legend__rule {
    display: flex;
    align-items: center;
    margin-bottom: .5em; }
    .carpool-legend__rule:last-child {
      margin-bottom: 0; }
  .carpool-legend__icon {
    margin-right: 1em; }

.carpool-driver {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .carpool-driver__image {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0 8px 24px rgba(64, 210, 166, 0.7); }
  .carpool-driver__information {
    margin-left: 1em; }
  .carpool-driver__instruction {
    flex: 0 1 100%;
    margin-top: .25em; }
  .carpool-driver__phone {
    color: #00C389; }

.carpool-block--expanded, .carpool-block--highlight {
  background-color: #E6F9F3; }
  .carpool-block--expanded:hover, .carpool-block--highlight:hover {
    background-color: #E6F9F3; }
  .carpool-block--expanded .carpool-block__expand-label, .carpool-block--highlight .carpool-block__expand-label {
    color: #00C389; }

.carpool-block__header {
  cursor: default;
  margin-bottom: 0; }

.carpool-block__label {
  color: #a4a4a4; }

.carpool-block__expand-label {
  cursor: pointer; }

.carpool-block__expand-icon {
  margin-left: .5em; }

.carpool-drivers-list {
  margin-top: 1rem; }
  .carpool-drivers-list__driver {
    margin-top: .8rem; }

.training-block__description {
  margin-top: 0;
  margin-bottom: .75rem; }

.training-block__textlink--delete {
  margin-right: 1.5rem; }
  .training-block__textlink--delete::before {
    content: "\63"; }

.training-note__title {
  margin-bottom: 0.3125rem;
  letter-spacing: 0.018em;
  font-family: Rucksack; }

.training-note__content {
  white-space: pre-line; }

.calendar-filter {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .calendar-filter--loading::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10; }
  .calendar-filter__event {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff7555; }
  .calendar-filter .flatpickr-calendar {
    width: 100%;
    box-shadow: none;
    font-family: Rucksack, sans-serif;
    font-weight: 700;
    font-size: 1rem; }
  .calendar-filter .flatpickr-months {
    padding-bottom: 20px; }
    .calendar-filter .flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
    .calendar-filter .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
      left: auto;
      right: 40px; }
  .calendar-filter .flatpickr-current-month {
    text-align: left;
    left: 0;
    display: flex; }
    .calendar-filter .flatpickr-current-month .numInputWrapper {
      width: 50px;
      display: inline-block;
      line-height: 0; }
    .calendar-filter .flatpickr-current-month input.cur-year {
      padding: 0;
      width: 50px;
      order: 1;
      font-family: Rucksack, sans-serif;
      font-weight: 700;
      font-size: 1rem;
      color: #a4a4a4; }
  .calendar-filter .flatpickr-monthDropdown-months {
    border: none;
    padding: 0;
    height: 1.2rem;
    width: auto;
    order: 2; }
  .calendar-filter .flatpickr-next-month, .calendar-filter .flatpickr-prev-month {
    padding: 6px;
    border-radius: 50%;
    border: 1px solid #bebebe; }
    .calendar-filter .flatpickr-next-month svg path, .calendar-filter .flatpickr-prev-month svg path {
      fill: #00C389; }
  .calendar-filter .flatpickr-weekday {
    text-transform: uppercase;
    color: #000000;
    opacity: 1; }
  .calendar-filter .flatpickr-innerContainer, .calendar-filter .flatpickr-rContainer {
    display: block; }
  .calendar-filter .flatpickr-days {
    width: auto; }
    .calendar-filter .flatpickr-days .dayContainer {
      width: 100%;
      min-width: auto;
      max-width: none; }
  .calendar-filter .flatpickr-day {
    position: relative;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    max-width: none;
    font-weight: 700;
    color: #00C389; }
    .calendar-filter .flatpickr-day:hover {
      border-color: transparent;
      background-color: transparent; }
      .calendar-filter .flatpickr-day:hover::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background: #f5f5f5; }
    .calendar-filter .flatpickr-day.prevMonthDay, .calendar-filter .flatpickr-day.nextMonthDay {
      color: #a4a4a4; }
    .calendar-filter .flatpickr-day.today {
      color: #ffffff;
      border-color: transparent;
      background-color: transparent; }
      .calendar-filter .flatpickr-day.today::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background: #00C389; }
    .calendar-filter .flatpickr-day.selected {
      color: #ffffff;
      border-color: transparent;
      background-color: transparent; }
      .calendar-filter .flatpickr-day.selected::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background: #00C389; }

.calendar-legend__color {
  display: inline-block;
  margin-right: .5em;
  margin-bottom: .5em;
  margin-top: .25em;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  border-radius: 50%; }
  .calendar-legend__color--today {
    background: #00C389; }
  .calendar-legend__color--selected {
    background: #00C389; }
  .calendar-legend__color--planned {
    background: #ff7555; }

.sos-course {
  color: #000000;
  display: flex;
  flex-wrap: wrap; }
  .sos-course__block {
    color: #000000;
    display: inline-block; }
    .sos-course__block--type {
      width: 100%;
      flex: 1 1 100%; }
      @media (min-width: 1200px) {
        .sos-course__block--type {
          width: 15%;
          flex: 1 1 15%; } }
      .sos-course__block--type .sos-course__value {
        font-family: Rucksack;
        font-weight: 800; }
    .sos-course__block--location {
      width: 100%;
      flex: 1 1 100%; }
      @media (min-width: 992px) {
        .sos-course__block--location {
          width: 50%;
          flex: 1 1 50%; } }
      @media (min-width: 1200px) {
        .sos-course__block--location {
          width: 30%;
          flex: 1 1 30%; } }
    .sos-course__block--social-worker {
      width: 100%;
      flex: 1 1 100%; }
      @media (min-width: 992px) {
        .sos-course__block--social-worker {
          width: 50%;
          flex: 1 1 50%; } }
      @media (min-width: 1200px) {
        .sos-course__block--social-worker {
          width: 30%;
          flex: 1 1 30%; } }
    .sos-course__block--link {
      margin-top: 1em;
      white-space: nowrap;
      flex: 1 1 auto;
      align-self: flex-end; }
      @media (min-width: 1200px) {
        .sos-course__block--link {
          margin-top: 0; } }
  .sos-course__label {
    color: #c9c9c9;
    font-size: 0.75rem;
    letter-spacing: 0.018em;
    font-family: Rucksack; }
    @media (min-width: 768px) {
      .sos-course__label {
        margin-bottom: 0.5em; } }
  .sos-course__value {
    color: #000000;
    font-size: 1rem;
    letter-spacing: 0.018em;
    font-weight: 400;
    margin-bottom: 0.5em;
    display: inline-block; }
    @media (min-width: 1200px) {
      .sos-course__value {
        margin-bottom: 0; } }
  .sos-course__collapsible {
    border: 1px solid #c9c9c9;
    padding: 8px 16px;
    margin-top: -1.25rem;
    margin-bottom: 1.5rem; }

.summary-block__title {
  display: flex;
  justify-content: space-between; }

.summary-block__title-icon {
  display: inline-block;
  height: 1.4em;
  width: 1.4em; }
  .summary-block__title-icon svg {
    height: 100%;
    width: 100%; }

.summary-block__content {
  margin-bottom: 1em; }

.summary-block__list {
  line-height: 1.5em; }

.messages-block {
  padding: 0 25px;
  position: relative;
  color: #000000; }
  .messages-block__item {
    padding-bottom: 25px;
    padding-top: 25px; }
    .messages-block__item:not(:last-child) {
      border-bottom: 1px solid #c9c9c9; }
  .messages-block__author {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
  .messages-block__author-image-wrapper {
    border-radius: 50%;
    display: inline-block;
    height: 35px;
    overflow: hidden;
    width: 35px; }
  .messages-block__author-image {
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .messages-block__author-inner {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    padding-left: 10px; }
  .messages-block__author-name {
    flex: 1 1 100%; }
  .messages-block__author-function {
    -webkit-font-smoothing: antialiased;
    color: #bebebe;
    flex: 1 1 100%;
    font-weight: 800; }
  .messages-block__date {
    color: #bebebe;
    font-size: 0.67em;
    margin-bottom: .25em;
    margin-top: .5em; }
  .messages-block__status {
    position: absolute;
    top: 20px;
    right: 20px; }
  .messages-block__content {
    flex: 1 1 100%;
    font-family: Rucksack;
    font-weight: 800;
    letter-spacing: 0.019em;
    margin-bottom: 0.25em;
    margin-top: 0.5em; }
  .messages-block__link {
    margin-top: 1em;
    flex: 1 1 100%; }

.social-worker {
  align-items: center;
  display: flex;
  flex-wrap: wrap; }
  .social-worker__image-wrapper {
    border-radius: 50%;
    box-shadow: 0 8px 24px rgba(64, 210, 166, 0.7);
    display: inline-block;
    height: 45px;
    overflow: hidden;
    width: 45px; }
    @media (min-width: 768px) {
      .social-worker__image-wrapper {
        height: 65px;
        width: 65px; } }
  .social-worker__image {
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .social-worker__inner {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    padding-left: 20px; }
  .social-worker__info {
    flex: 1 1 100%;
    display: block;
    color: #000000;
    margin: 0.5em 0; }
    .social-worker__info--phone, .social-worker__info--mail {
      text-decoration: underline; }

.client-info__row {
  margin-bottom: 1em; }

.client-info__label {
  display: inline-block;
  font-weight: 800; }

.client-info__value {
  display: inline-block; }

.client-info__link {
  display: block;
  margin-bottom: 40px; }

.institute-info__row {
  margin-bottom: 1em; }

.institute-info__label {
  display: inline-block;
  font-weight: 800; }

.institute-info__value {
  display: inline-block; }

.intake-info__row {
  margin-bottom: 1em; }

.intake-info__label {
  display: inline-block;
  font-weight: 800; }

.intake-info__value {
  display: inline-block; }

.status-indicator {
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 1em; }
  .status-indicator--approved, .status-indicator--payout, .status-indicator--payed {
    background: #55b890; }
  .status-indicator--progress {
    background: #ff8600; }
  .status-indicator--concept {
    background: #4158f6; }
  .status-indicator--denied {
    background: #e50044; }

.numeric-input {
  position: relative;
  display: block;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .numeric-input::before {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 2.15em;
    bottom: 1px;
    content: "€";
    line-height: 3.05em;
    text-align: center;
    font-size: 1em; }
  .numeric-input input {
    padding-left: 2.75em; }

.submit-status svg {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em; }

.submit-status--success svg {
  fill: #55b890; }

.submit-status--error svg {
  fill: #ff7555; }

.splashscreen {
  height: 100vh;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 195, 137, 0.7);
  z-index: 250;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: opacity ease-in 0.28s;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .splashscreen--hide {
    opacity: 0; }
  @media (min-width: 1600px) {
    .splashscreen {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .splashscreen::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 195, 137, 0.7);
    mix-blend-mode: multiply; }
  .splashscreen__slider {
    height: 100%;
    width: 100%;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .splashscreen__slider.flickity-enabled .splashscreen__slide {
      opacity: 1; }
  .splashscreen__slide {
    color: #ffffff;
    flex: 1 1 100%;
    display: flex;
    min-height: 100%;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 0; }
    .splashscreen__slide:first-child {
      opacity: 1; }
  .splashscreen__image {
    width: 100%; }
  .splashscreen__avatar {
    width: 120px;
    height: 120px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    border-radius: 50%; }
  .splashscreen__avatar-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; }
  .splashscreen__title {
    margin-bottom: 0.75em; }
    @media (min-width: 992px) {
      .splashscreen__title--large {
        font-size: 3.75rem; } }
  .splashscreen__content {
    font-size: 20px;
    line-height: 1.5em;
    letter-spacing: 0.049em; }
  .splashscreen__icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 0; }
    .splashscreen__icon svg {
      fill: #40D2A6;
      width: 1em;
      height: 1em; }
  .splashscreen__emoji {
    font-size: 3.75rem; }
  .splashscreen__close-splashscreen {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid #c9c9c9;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #ffffff; }
    .splashscreen__close-splashscreen:hover {
      cursor: pointer; }
    .splashscreen__close-splashscreen svg {
      width: 20px;
      height: 20px;
      fill: #00C389;
      vertical-align: middle; }
  .splashscreen .flickity-button {
    border: 1px solid #c9c9c9;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #ffffff; }
    .splashscreen .flickity-button[disabled] {
      display: none; }
    .splashscreen .flickity-button.previous {
      left: 5%; }
    .splashscreen .flickity-button.next, .splashscreen .flickity-button.finish {
      right: 5%; }
    .splashscreen .flickity-button svg {
      fill: #00C389; }
  .splashscreen .flickity-page-dots {
    bottom: 0;
    text-align: center; }
    .splashscreen .flickity-page-dots .dot {
      transition: all 0.25s ease-in-out;
      background: transparent;
      border: 2px solid #ffffff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      margin-left: 8px;
      margin-right: 8px; }
      .splashscreen .flickity-page-dots .dot.is-selected {
        background-color: #40D2A6; }
    .splashscreen .flickity-page-dots::after {
      content: url("data:image/svg+xml; utf8, <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.71789 15.4777C7.0818 15.8165 7.5513 16 8.03839 16C8.13412 16 8.22985 15.9929 8.32628 15.9788C8.91332 15.892 9.42224 15.5468 9.72139 15.0323L15.8642 2.01599C16.1972 1.31083 15.8966 0.468721 15.1934 0.136256C14.4938 -0.197621 13.6519 0.10308 13.3197 0.808951L7.7864 12.5426L2.41149 7.06509C1.86527 6.51027 0.974846 6.50321 0.420181 7.04956C-0.133781 7.59661 -0.14082 8.49024 0.403991 9.04646L6.71789 15.4777Z' fill='white'/></svg>");
      display: inline-block;
      margin-left: 8px; }

.notification-box {
  border: 1px solid #c9c9c9;
  background-color: #ffffff;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  padding: 20px; }
  @media (min-width: 576px) {
    .notification-box {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .notification-box__text {
    font-size: 0.9rem; }
  .notification-box__button {
    flex-shrink: 0; }
  .notification-box--with-button .notification-box__text {
    padding-bottom: 20px; }
    @media (min-width: 576px) {
      .notification-box--with-button .notification-box__text {
        padding-right: 20px;
        padding-bottom: 0; } }

.collapsible {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .collapsible__button {
    line-height: 20px; }
  .collapsible__button-icon {
    display: inline-block;
    width: 12px;
    height: 20px;
    margin-right: 5px;
    padding-top: 4px; }
    .collapsible__button-icon svg {
      max-width: 100%;
      transition: all 0.25s ease-in-out; }
    .collapsible__button-icon--open svg {
      transform: rotate(180deg); }
  .collapsible__button-text {
    cursor: pointer;
    color: #00C389;
    font-family: Rucksack;
    font-weight: 800;
    font-size: 0.75rem;
    letter-spacing: 0.018em;
    text-decoration: underline;
    text-transform: uppercase; }
    .collapsible__button-text:hover {
      color: #009065; }
  .collapsible__content {
    padding-top: 20px; }
  .collapsible .slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in; }
  .collapsible .slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }
  .collapsible .slide-enter-to, .collapsible .slide-leave {
    max-height: 800px;
    overflow: hidden; }
  .collapsible .slide-enter, .collapsible .slide-leave-to {
    overflow: hidden;
    max-height: 0; }

/* stylelint-disable */
.grecaptcha-badge {
  display: none !important; }

/* stylelint-enable */
/* stylelint-disable */
.cc-btn {
  flex: none !important; }

.cc-revok {
  display: none !important; }

/* stylelint-enable */
.login-wrapper {
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 0.25rem;
  padding: 20px; }
  @media (min-width: 768px) {
    .login-wrapper {
      padding: 30px 25px; } }

.login__password-reset {
  height: 40px;
  display: flex;
  align-items: center; }

.login__bottom {
  margin-top: 40px; }

.login__two-factor-input {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center; }
