.block-header {
    display: block;
    cursor: pointer;
    margin: -20px;
    transition: $transition-default;
    color: map-deep-get($colors, "black", "default");

    @include media-breakpoint-up(lg) {
        margin: -20px;
        padding: 20px;
    }

    &--expandable {
        cursor: pointer;
    }

    &__top {
        font-size: rem-calc(12);
        text-transform: uppercase;
        color: map-deep-get($colors, "gray", "darker");
        letter-spacing: 0.019em;
        font-weight: 700;
        font-family: $headings-font-family;
        margin: 0 -0.5em;
    }

    &__top-value {
        display: inline-block;
        margin-bottom: .5em;
        padding-left: .5em;
        padding-right: .5em;
    }

    &__top-icon {
        display: inline-block;
        padding-left: 0.5em;
        padding-right: 0.5em;
        vertical-align: middle;

        svg {
            width: rem-calc(18);
            height: rem-calc(18);
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__expand-label {
        color: map-deep-get($colors, "gray", "darker");
        font-family: $headings-font-family;
        font-size: rem-calc(12);
        font-weight: 600;
        text-transform: uppercase;
    }

    &__expand-icon {
        transition: $transition-default;
        align-items: center;
        background: map-deep-get($colors, "white", "default");
        border-radius: 50%;
        border: 0.705px solid map-deep-get($colors, "gray", "default");
        display: inline-flex;
        height: rem-calc(22);
        justify-content: center;
        width: rem-calc(22);

        &--usd {
            transform: rotate(180deg);
        }

        &--right {
            transform: rotate(-90deg);
        }

        svg {
            display: inline-block;
            fill: map-deep-get($colors, "blue", "dark");
            height: rem-calc(11);
            width: rem-calc(11);
        }
    }
}
