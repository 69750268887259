.text-link {
    border: 0;
    box-shadow: none;
    background: none;
    cursor: pointer;
    color: map-deep-get($colors, "blue", "dark");
    font-family: $headings-font-family;
    font-weight: 800;
    font-size: rem-calc(12);
    letter-spacing: 0.018em;
    line-height: 1.2em;
    white-space: nowrap;
    outline: none;
    padding: 0;
    text-decoration: underline;
    text-transform: uppercase;
    transition: $transition-default;

    &::before {
        content: "\63";
        display: inline-block;
        font-family: gzn;
        font-weight: 400;
        margin-right: 0.5em;
        text-transform: none;
        transition: $transition-default;
        vertical-align: middle;
    }

    &:hover {
        color: darken(map-deep-get($colors, "blue", "dark"), 10%);

        &::before {
            margin-left: 0.3em;
            margin-right: 0.2em;
        }
    }

    &--white {
        color: map-deep-get($colors, "white", "default");

        &:hover {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &--delete {
        color: map-deep-get($colors, "validation", "invalid");

        &::before {
            content: "\65";
        }

        &:hover {
            color: darken(map-deep-get($colors, "validation", "invalid"), 10%);

            &::before {
                margin-left: 0;
                margin-right: 0.5em;
            }
        }
    }
}