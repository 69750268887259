.carpool {
    &__header {
        margin-bottom: 0;
    }

    &__map {
        height: 100%;
        min-height: 800px;
        margin-left: -10px;
        margin-right: -10px;
    }

    &__sidebar {
        padding: 20px 0;

        @include media-breakpoint-up(xl) {
            padding: 50px 40px;
        }
    }
}