.main {
    max-width: 100%;
    height: 100%;
    //z-index: 1;
    position: relative;
    transition: $transition-default;
    padding-top: $header-height;

    &__inner {
        height: 100%;
    }

    &--menu-expanded {
        @include media-breakpoint-up(xl) {
            padding-left: map-get($sidebar-width, "xl");
        }

        @include media-breakpoint-up(xxl) {
            padding-left: map-get($sidebar-width, "xxl");
        }
    }
}
