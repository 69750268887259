.block-header-options {
    &__label {
        position: relative;
        color: map-deep-get($colors, "brand", "primary");
        cursor: pointer;

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__options-list {
        display: none;
        position: absolute;
        z-index: 9;
        right: 0;
        bottom: -5px;
        transform: translateY(100%);
        padding: 10px;
        border-radius: $border-radius;
        background: map-deep-get($colors, "brand", "primary");
        box-shadow: $box-shadow-primary;
        cursor: default;

        &--open {
            display: block;
        }
    }

    &__option-link {
        white-space: nowrap;
        color: map-deep-get($colors, "white", "default");

        &:not(:last-child) {
            margin-bottom: rem-calc(5);
        }
    }
}