.declaration-distance {
    position: relative;
    $elm: &;

    &__value {
        opacity: 0;
    }

    &__value,
    &__input {
        background: none;
        padding: 0;
        margin: 0;
        border: 0;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
        display: inline-block;
        width: auto;
        line-height: inherit;
        letter-spacing: inherit;
    }

    &__input {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        opacity: 1;
        box-shadow: 0 2px 0 map-deep-get($colors, "black", "default");

        &:focus {
            border: 0;
        }
    }

    &__compensation {
        box-shadow: 0 2px 0 map-deep-get($colors, "black", "default");
    }

    &__notice {
        display: block;
        margin-top: rem-calc(15);
        font-size: $font-size-base * 0.8;

        &--busy {
            color: map-deep-get($colors, "gray", "dark");
        }

        &--error {
            color: map-deep-get($colors, "validation", "invalid");

            svg {
                fill: map-deep-get($colors, "validation", "invalid");
            }
        }

        &--success {
            color: map-deep-get($colors, "validation", "valid");

            svg {
                fill: map-deep-get($colors, "validation", "valid");
            }
        }

        svg {
            height: rem-calc(9);
            width: rem-calc(9);
            margin-right: rem-calc(5);
        }
    }
}