.dataset-loader {
    text-align: center;
    border-radius: $border-radius;
    background-color: map-deep-get($colors, "white", "default");
    padding: $grid-gutter-width;

    &:not(.dataset-loader--noborder) {
        border: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__icon {
        margin-bottom: $grid-gutter-width;
        width: 50px;
        height: 50px;
        display: inline-block;

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        line-height: 1.25em;
        letter-spacing: 0.018em;
        font-size: rem-calc(12);
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: $grid-gutter-width;
    }

    &__progress {
        height: 20px;
        border: 1px solid map-deep-get($colors, "brand", "primary-light");
        background-color: map-deep-get($colors, "gray", "light");
        text-align: left;
        border-radius: $border-radius;
        overflow: hidden;
    }

    &__bar {
        height: 100%;
        background-color: map-deep-get($colors, "brand", "primary");
        transition: all 0.5s;
        position: relative;

        &::after {
            content: " ";
            height: 110%;
            background-color: map-deep-get($colors, "brand", "primary");
            position: absolute;
            right: -4px;
            top: -3px;
            width: 15px;
            transform: rotate(20deg);
        }
    }
}
