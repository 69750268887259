.button {
    padding: 1.16em 1.22em;
    min-height: rem-calc(45);
    line-height: 1.25em;
    letter-spacing: 0.018em;
    font-size: rem-calc(12);
    border-radius: $border-radius;
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 800;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
        vertical-align: middle;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--mt {
        margin-top: $grid-gutter-width;
    }

    &--mb {
        margin-bottom: $grid-gutter-width;
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
            }
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "gray", "light");
            }
        }
    }

    &--darkblue {
        background-color: map-deep-get($colors, "blue", "dark");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: darken(map-deep-get($colors, "blue", "dark"), 10%);
            }
        }
    }

    &__addition {
        display: block;
        font-size: rem-calc(14);
        color: map-deep-get($colors, "gray", "dark");
        margin-top: 0.5em;
    }

    &:disabled {
        background: map-deep-get($colors, "gray", "default");
        color: map-deep-get($colors, "gray", "darker");
        cursor: not-allowed;
    }
}
