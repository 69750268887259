.header {
    background: map-deep-get($colors, "blue", "dark");
    height: $header-height;
    overflow: hidden;
    transition: $transition-default;
    width: 100%;
    z-index: 90;
    $header: &;

    &--menuExpanded {
        height: auto;
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        align-items: center;
        display: flex;
        left: 0;
        overflow: visible;
        padding-left: map-get($sidebar-width, "md");
        position: fixed;
        right: 0;
        top: 0;
    }

    @include media-breakpoint-up(xl) {
        padding-left: map-get($sidebar-width, "xl");
    }

    @include media-breakpoint-up(xxl) {
        padding-left: map-get($sidebar-width, "xxl");
    }

    &__container {
        max-width: none;

        @include media-breakpoint-up(xl) {
            max-width: 75vw;
        }
    }

    &__bottom {
        width: 100%;
        display: block;
        border-bottom: 0.5px solid map-deep-get($colors, "gray", "default");
    }
}
