.training-block {
    &__description {
        margin-top: 0;
        margin-bottom: .75rem;
    }

    &__textlink {
        &--delete {
            margin-right: 1.5rem;

            &::before {
                content: "\63";
            }
        }
    }
}