.news-block {
    display: flex;
    flex-wrap: wrap;
    color: map-deep-get($colors, "black", "default");
    $elm: &;

    &__image-wrapper {
        display: inline-block;
        position: relative;
        max-width: 30%;
        flex: 1 1 30%;

        @include media-breakpoint-up(md) {
            max-width: 20%;
            flex: 1 1 20%;
        }

        @include media-breakpoint-up(lg) {
            max-width: 30%;
            flex: 1 1 30%;
        }
    }

    &__image {
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: contain;
        object-position: top;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    &__content {
        display: inline-flex;
        flex-wrap: wrap;
        max-width: 70%;
        flex: 1 1 70%;
        padding-left: #{$grid-gutter-width / 2};

        @include media-breakpoint-up(md) {
            max-width: 80%;
            flex: 1 1 80%;
        }

        @include media-breakpoint-up(lg) {
            max-width: 70%;
            flex: 1 1 70%;
        }
    }

    &__content-inner {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;
        align-self: flex-start;
    }

    &__date {
        margin-bottom: 0;
        color: map-deep-get($colors, "gray", "default");
        flex: 1 1 100%;
    }

    &__title {
        margin-top: 0;
        flex: 1 1 100%;
    }

    &__bottom {
        align-self: flex-end;
        flex: 1 1 100%;
    }

    &__author {
        color: map-deep-get($colors, "gray", "default");
        font-weight: 400;
        font-size: rem-calc(12);
    }

    &--wide {

        @include media-breakpoint-up(md) {
            #{$elm}__image-wrapper {
                width: auto;
                flex: 1 1 120px;
                height: 70px;

                @include media-breakpoint-up(md) {
                    height: auto;
                    max-width: 20%;
                    flex: 1 1 20%;
                }
            }

            #{$elm}__content {
                flex: 1 1 auto;
                width: auto;

                @include media-breakpoint-up(md) {
                    flex: 1 1 80%;
                    max-width: 80%;
                }
            }
        }

        #{$elm}__link {
            flex: 1 0 100%;
            margin-top: 20px;

            @include media-breakpoint-up(md) {
                flex: 1 0 auto;
                align-self: center;
            }
        }
    }
}