.textarea {
    font-size: .875rem;
    font-weight: normal;
    letter-spacing: .500px;
}

.radio-wrapper {
    $el: &;
    &--disabled {
        #{$el}__label, label {
            color: map-deep-get($colors, "gray", "default");
        }
    }
    &__addition {
        margin-left: 10px;
        font-size: 85%;
        &--red {
            color: map-deep-get($colors, "validation", "invalid");
        }
    }
}
