/* stylelint-disable */
.row-custom-gutter {
    margin-left: -#{$grid-gutter-width / 8};
    margin-right: -#{$grid-gutter-width / 8};

    .col, div[class^="col-"] {
        padding-left: #{$grid-gutter-width / 8};
        padding-right: #{$grid-gutter-width / 8};
    }

    @include media-breakpoint-up(lg) {
        margin-left: -#{$grid-gutter-width / 2};
        margin-right: -#{$grid-gutter-width / 2};

        .col, div[class^="col-"] {
            padding-left: #{$grid-gutter-width / 2};
            padding-right: #{$grid-gutter-width / 2};
        }
    }
}

.container {
    @include media-breakpoint-up(md) {
        max-width: 70vw;
    }

    @include media-breakpoint-up(lg) {
        max-width: 75vw;
    }

    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, 'xl');
    }
}
/* stylelint-enable */

.text-center {
    text-align: center;
}