.intake-info {
    &__row {
        margin-bottom: 1em;
    }

    &__label {
        display: inline-block;
        font-weight: 800;
    }

    &__value {
        display: inline-block;
    }
}