.logo {
    display: inline-block;
    height: 55px;
    width: 126px;
    margin: 5px 0;

    @include media-breakpoint-up(xl) {
        height: 67px;
        margin: 50px auto;
        width: 156px;
    }

    &--auth {
        height: 98.78px;
        margin-top: 0;
        margin-bottom: 50px;
        margin-left: 0;
        width: 230px;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: map-deep-get($colors, "brand", "primary");
    }

    &--white svg{
        fill: map-deep-get($colors, "white", "default");
    }

    img {
        height: auto;
        max-width: 100%;
    }
}
