.calendar-filter {
    position: relative;

    &--loading {
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(map-deep-get($colors, "white", "default"), 0.7);
            z-index: 10;
        }
    }

    &__event {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: map-deep-get($colors, "orange", "default");
    }

    /* stylelint-disable */
    .flatpickr {
        $elm: &;

        &-calendar {
            width: 100%;
            box-shadow: none;
            font-family: Rucksack, sans-serif;
            font-weight: 700;
            font-size: rem-calc(16);
        }

        &-months {
            padding-bottom: 20px;

            .flatpickr-next-month.flatpickr-prev-month,
            .flatpickr-prev-month.flatpickr-prev-month {
                left: auto;
                right: 40px;
            }
        }

        &-current-month {
            text-align: left;
            left: 0;
            display: flex;

            .numInputWrapper {
                width: 50px;
                display: inline-block;
                line-height: 0;
            }

            input.cur-year {
                padding: 0;
                width: 50px;
                order: 1;
                font-family: Rucksack, sans-serif;
                font-weight: 700;
                font-size: rem-calc(16);
                color: map-deep-get($colors, "gray", "darker");
            }
        }

        &-monthDropdown-months {
            border: none;
            padding: 0;
            height: 1.2rem;
            width: auto;
            order: 2;
        }

        &-next-month,
        &-prev-month {
            padding: 6px;
            border-radius: 50%;
            border: 1px solid map-deep-get($colors, "gray", "dark");

            svg path {
                fill: map-deep-get($colors, "blue", "dark");
            }
        }

        &-weekday {
            text-transform: uppercase;
            color: map-deep-get($colors, "black", "default");
            opacity: 1;
        }

        &-innerContainer,
        &-rContainer {
            display: block;
        }

        &-days {
            width: auto;

            .dayContainer {
                width: 100%;
                min-width: auto;
                max-width: none;
            }
        }

        &-day {
            position: relative;
            flex-basis: calc(100% / 7);
            width: calc(100% / 7);
            max-width: none;
            font-weight: 700;
            color: map-deep-get($colors, "brand", "primary");

            &:hover {
                border-color: transparent;
                background-color: transparent;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                    background: map-deep-get($colors, "gray", "light");
                }
            }

            &.prevMonthDay,
            &.nextMonthDay {
                color: map-deep-get($colors, "gray", "darker");
            }

            &.today {
                color: map-deep-get($colors, "white", "default");
                border-color: transparent;
                background-color: transparent;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                    background: map-deep-get($colors, "brand", "primary");
                }
            }

            &.selected {
                color: map-deep-get($colors, "white", "default");
                border-color: transparent;
                background-color: transparent;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                    background: map-deep-get($colors, "blue", "dark");
                }
            }
        }
    }
    /* stylelint-enable */
}
