.numeric-input {
    position: relative;
    display: block;

    &::before {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 2.15em;
        bottom: 1px;
        content: "€";
        line-height: 3.05em;
        text-align: center;
        font-size: 1em;
    }

    /* stylelint-disable */
    input {
        padding-left: 2.75em;
    }
    /* stylelint-enable */
}
