.submit-status {
    svg {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.5em;
    }

    &--success {
        svg {
            fill: map-deep-get($colors, "validation", "valid");
        }
    }

    &--error {
        svg {
            fill: map-deep-get($colors, "validation", "invalid");
        }
    }
}