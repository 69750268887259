.report-step {
    &__divider {
        margin-top: 40px;
        margin-bottom: 40px;
        border: 0;
        border-top: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__button {
        transition: all $transition-default-time $transition-default-time $transition-default-smoothing;
    }
}
