.kids-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__inner {
        flex: 1 0 auto;
    }

    &__title {
        color: map-deep-get($colors, "gray", "default");
        margin-bottom: .5em;
        letter-spacing: .018em;
        font-family: $headings-font-family;
    }

    &__kid {
        color: map-deep-get($colors, "black", "default");
    }

    &__present {
        width: 40%;
    }

    &__link {
        flex: 0 0 100%;
        margin-top: 1em;

        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
            margin-top: 0;
        }
    }
}
