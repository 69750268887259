.social-worker {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &__image-wrapper {
        border-radius: 50%;
        box-shadow: 0 8px 24px rgba(map-deep-get($colors, "blue", "default"), 0.7);
        display: inline-block;
        height: 45px;
        overflow: hidden;
        width: 45px;

        @include media-breakpoint-up(md) {
            height: 65px;
            width: 65px;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__inner {
        align-items: center;
        display: inline-flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        padding-left: $grid-gutter-width;
    }

    &__info {
        flex: 1 1 100%;
        display: block;
        color: map-deep-get($colors, "black", "default");
        margin: 0.5em 0;

        &--phone,
        &--mail {
            text-decoration: underline;
        }
    }
}