.carpool-driver {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__image {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0 8px 24px rgba(map-deep-get($colors, "blue", "default"), 0.7);
    }

    &__information {
        margin-left: 1em;
    }

    &__instruction {
        flex: 0 1 100%;
        margin-top: .25em;
    }

    &__phone {
        color: map-deep-get($colors, "brand", "primary");
    }
}