.splashscreen {
    height: 100vh;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(map-deep-get($colors, "blue", "dark"), 0.7);
    z-index: 250;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 50px;
    transition: opacity ease-in 0.28s;

    &--hide {
        opacity: 0;
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "blue", "dark"), 0.7);
        mix-blend-mode: multiply;
    }

    &__slider {
        height: 100%;
        width: 100%;

        /* stylelint-disable */
            &.flickity-enabled .splashscreen__slide {
                opacity: 1;
            }
        /* stylelint-enable */
    }

    &__slide {
        color: map-deep-get($colors, "white", "default");
        flex: 1 1 100%;
        display: flex;
        min-height: 100%;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }
    }

    &__image {
        width: 100%;
    }

    &__avatar {
        width: 120px;
        height: 120px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        margin-bottom: 25px;
        border-radius: 50%;
    }

    &__avatar-photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

    &__title {
        margin-bottom: 0.75em;

        @include media-breakpoint-up(lg) {
            &--large {
                font-size: rem-calc(60);
            }
        }
    }

    &__content {
        font-size: 20px;
        line-height: 1.5em;
        letter-spacing: 0.049em;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;

        svg {
            fill: map-deep-get($colors, "blue", "default");
            width: 1em;
            height: 1em;
        }
    }

    &__emoji {
        font-size: rem-calc(60);
    }

    &__close-splashscreen {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 1px solid map-deep-get($colors, "gray", "default");
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        background-color: map-deep-get($colors, "white", "default");

        &:hover {
            cursor: pointer;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: map-deep-get($colors, "blue", "dark");
            vertical-align: middle;
        }
    }

    /* stylelint-disable */
    .flickity-button {
        border: 1px solid map-deep-get($colors, "gray", "default");
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        background-color: map-deep-get($colors, "white", "default");

        &[disabled] {
            display: none;
        }

        &.previous {
            left: 5%;
        }

        &.next,
        &.finish {
            right: 5%;
        }

        svg {
            fill: map-deep-get($colors, "blue", "dark");
        }
    }

    .flickity-page-dots {
        bottom: 0;
        text-align: center;

        .dot {
            transition: $transition-default;
            background: transparent;
            border: 2px solid map-deep-get($colors, "white", "default");
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            margin-left: 8px;
            margin-right: 8px;

            &.is-selected {
                background-color: map-deep-get($colors, "blue", "default");
            }
        }

        &::after {
            content: url("data:image/svg+xml; utf8, <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.71789 15.4777C7.0818 15.8165 7.5513 16 8.03839 16C8.13412 16 8.22985 15.9929 8.32628 15.9788C8.91332 15.892 9.42224 15.5468 9.72139 15.0323L15.8642 2.01599C16.1972 1.31083 15.8966 0.468721 15.1934 0.136256C14.4938 -0.197621 13.6519 0.10308 13.3197 0.808951L7.7864 12.5426L2.41149 7.06509C1.86527 6.51027 0.974846 6.50321 0.420181 7.04956C-0.133781 7.59661 -0.14082 8.49024 0.403991 9.04646L6.71789 15.4777Z' fill='white'/></svg>");
            display: inline-block;
            margin-left: 8px;

        }
    }
    /* stylelint-enable */
}
