.post {
    &__date {
        color: map-deep-get($colors, "gray", "default");
    }

    &__author {
        color: map-deep-get($colors, "gray", "default");
        font-size: rem-calc(12);
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
    }

    &__image {
        width: 100%;
        margin-bottom: #{$grid-gutter-width};
        margin-top: #{$grid-gutter-width};
    }
}