.calendar-legend {
    &__color {
        display: inline-block;
        margin-right: .5em;
        margin-bottom: .5em;
        margin-top: .25em;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        border-radius: 50%;

        &--today {
            background: map-deep-get($colors, "brand", "primary");
        }

        &--selected {
            background: map-deep-get($colors, "blue", "dark");
        }

        &--planned {
            background: map-deep-get($colors, "orange", "default");
        }
    }
}