.navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    max-height: 100%;
    display: flex;
    flex-flow: column;

    @include media-breakpoint-up(xl) {
        max-height: none;
        position: relative;
        display: block;
    }
}
