.attachments-input {
    &__wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }

    &__element {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
}