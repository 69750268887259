.service-menu {
    display: inline-flex;
    margin-left: -#{$grid-gutter-width / 2};
    margin-right: -#{$grid-gutter-width / 2};
    flex: 1 1 100%;

    @include media-breakpoint-up(xl) {
        flex: 0 0 auto;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(xl) {
            width: auto;
        }
    }

    &__item {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "white", "default");
        display: inline-block;
        padding-left: #{$grid-gutter-width / 2};
        padding-right: #{$grid-gutter-width / 2};
        font-family: $headings-font-family;
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: 0.005em;
        font-weight: 800;
        position: relative;
        flex: 1 1 100%;

        @include media-breakpoint-up(xl) {
            flex: 0 0 auto;
        }
    }

    &__link {
        -webkit-font-smoothing: antialiased;
        color: inherit;
        display: inline-block;
        font-family: inherit;
        font-size: inherit;
        margin: 10px 10px 10px 0;
        text-transform: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
        padding: 10px 15px;
        position: relative;
        transition: $transition-default;

        @include media-breakpoint-up(xl) {
            margin: 0;
            padding: 0;
        }

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__icon {
        fill: map-deep-get($colors, "white", "default");
        display: inline-flex;
        vertical-align: text-bottom;
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.25em;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__value {
        position: relative;
    }

    &__index {
        -webkit-font-smoothing: antialiased;
        height: 1.25em;
        min-width: 1.25em;
        line-height: 1.25em;
        border-radius: 50%;
        background: map-deep-get($colors, "orange", "default");
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(12);
        position: absolute;
        bottom: 95%;
        right: -.75em;
        display: inline-block;
        text-align: center;
    }
}
