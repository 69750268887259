.sos-course {
    color: map-deep-get($colors, "black", "default");
    display: flex;
    //align-items: flex-end;
    flex-wrap: wrap;
    $elm: &;

    &__block {
        color: map-deep-get($colors, "black", "default");
        display: inline-block;

        &--type {
            width: 100%;
            flex: 1 1 100%;

            @include media-breakpoint-up(xl) {
                width: 15%;
                flex: 1 1 15%;
            }

            #{$elm}__value {
                font-family: $headings-font-family;
                font-weight: 800;
            }
        }

        &--location {
            width: 100%;
            flex: 1 1 100%;

            @include media-breakpoint-up(lg) {
                width: 50%;
                flex: 1 1 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 30%;
                flex: 1 1 30%;
            }
        }

        &--social-worker {
            width: 100%;
            flex: 1 1 100%;

            @include media-breakpoint-up(lg) {
                width: 50%;
                flex: 1 1 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 30%;
                flex: 1 1 30%;
            }
        }

        &--link {
            margin-top: 1em;
            white-space: nowrap;
            flex: 1 1 auto;
            align-self: flex-end;

            @include media-breakpoint-up(xl) {
                margin-top: 0;
            }
        }
    }

    &__label {
        color: map-deep-get($colors, "gray", "default");
        font-size: rem-calc(12);
        letter-spacing: 0.018em;
        font-family: $headings-font-family;

        @include media-breakpoint-up(md) {
            margin-bottom: 0.5em;
        }
    }

    &__value {
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(16);
        letter-spacing: 0.018em;
        font-weight: 400;
        margin-bottom: 0.5em;
        display: inline-block;

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
        }
    }

    &__collapsible {
        border: 1px solid map-deep-get($colors, "gray", "default");
        padding: 8px 16px;
        margin-top: rem-calc(-20);
        margin-bottom: rem-calc(24);
    }
}
