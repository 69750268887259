.status-indicator {
    border-radius: 50%;
    display: inline-block;
    height: 1em;
    margin-right: 0.5em;
    vertical-align: middle;
    width: 1em;

    &--approved,
    &--payout,
    &--payed {
        background: map-deep-get($colors, "status", "approved");
    }

    &--progress {
        background: map-deep-get($colors, "status", "progress");
    }

    &--concept {
        background: map-deep-get($colors, "status", "concept");
    }

    &--denied {
        background: map-deep-get($colors, "status", "denied");
    }
}
