.summary-block{
    &__title {
        display: flex;
        justify-content: space-between;
    }

    &__title-icon {
        display: inline-block;
        height: 1.4em;
        width: 1.4em;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &__content {
        margin-bottom: 1em;
    }

    &__list {
        line-height: 1.5em;
    }
}