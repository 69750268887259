.promotion {
    color: map-deep-get($colors, "black", "default");
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    $elm: &;

    &__radio:checked + #{$elm} {
        background: map-deep-get($colors, "brand", "primary-light");
    }

    &__content {
        &--top {
            display: flex;
            margin-bottom: 0.5em;
        }

        &--bottom {
            margin-bottom: 0.5em;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    &__content-item {
        margin-right: rem-calc(20);
        letter-spacing: 0.018em;

        &--date {
            font-weight: 700;
        }

        &--title {
            font-weight: 700;
        }

        &--date,
        &--time-start,
        &--location {
            text-transform: uppercase;
            font-size: rem-calc(12);
            font-family: $headings-font-family;
        }
    }

    &__text-link {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
            align-self: flex-end;
        }
    }
}
