.avatar-cropper {
    .avatar-cropper-overlay {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        //position: fixed;
        //top:0;
        //left:0;
        //right:0;
        //bottom:0;
        //z-index: 99999;
    }

    .avatar-cropper-img-input {
        display: none;
    }

    .avatar-cropper-close {
        //float: right;
        //padding: 20px;
        //font-size: 3rem;
        //color: #fff;
        //font-weight: 100;
        //text-shadow: 0px 1px rgba(40, 40, 40,.3);
        display: none;
    }

    .avatar-cropper-mark {
        //position: fixed;
        //top:0;
        //left:0;
        //right:0;
        //bottom:0;
        //background: rgba(0, 0, 0, .10);
    }

    .avatar-cropper-container {

        z-index: 999;


        .avatar-cropper-image-container {
            background: #fff;
            box-shadow: 1px 1px 5px rgba(100, 100, 100, .14);
            position: relative;
            max-width: 400px;
            height: 300px;
        }
        img {
            max-width: 100%;
            height: 100%;
        }

        //.avatar-cropper-footer {
        //    display: flex;
        //    align-items: stretch;
        //    align-content: stretch;
        //    justify-content: space-between;
        //
        //    .avatar-cropper-btn {
        //        width: 50%;
        //        padding: 15px 0;
        //        cursor: pointer;
        //        border: none;
        //        background: transparent;
        //        outline: none;
        //        &:hover {
        //            background-color: #2aabd2;
        //            color: #fff;
        //        }
        //    }
        //}
    }
}