.carpool-legend {
    display: none;
    padding: 1rem;
    margin-right: 10px;
    border-radius: $border-radius;
    box-shadow: 0 8px 24px rgba(map-deep-get($colors, "blue", "default"), 0.7);
    border: 1px solid map-deep-get($colors, "gray", "default");
    background-color: map-deep-get($colors, "white", "default");

    &__rule {
        display: flex;
        align-items: center;
        margin-bottom: .5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        margin-right: 1em;
    }
}