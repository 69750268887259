.attachments-download {
    display: flex;
    align-items: center;
    margin: rem-calc(20) 0;
    letter-spacing: 0.018em;

    &__icon {
        width: 40px;
        height: 40px;
        margin-right: rem-calc(20);
    }

    &__filename {
        font-weight: 700;
        text-transform: uppercase;
        font-size: rem-calc(12);
        font-family: $headings-font-family;
    }
}
