.collapsible {
    &__button {
        line-height: 20px;
    }

    &__button-icon {
        display: inline-block;
        width: 12px;
        height: 20px;
        margin-right: 5px;
        padding-top: 4px;

        svg {
            max-width: 100%;
            transition: $transition-default;
        }

        &--open {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__button-text {
        cursor: pointer;
        color: map-deep-get($colors, "blue", "dark");
        font-family: $headings-font-family;
        font-weight: 800;
        font-size: rem-calc(12);
        letter-spacing: 0.018em;
        text-decoration: underline;
        text-transform: uppercase;

        &:hover {
            color: darken(map-deep-get($colors, "blue", "dark"), 10%);
        }
    }

    &__content {
        padding-top: 20px;
    }

    /* stylelint-disable */
    .slide-enter-active {
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
    }

    .slide-leave-active {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to, .slide-leave {
        max-height: 800px;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
    /* stylelint-enable */
}
