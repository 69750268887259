.flickity-viewport {
    transition: height .3s ease-in-out;
}

.flickity-page-dots {
    text-align: right;

    .dot {
        margin: 0 3px;
        background-color: map-deep-get($colors, "gray", "darker");

        &.is-selected {
            background: map-deep-get($colors, "blue", "dark");
        }
    }
}