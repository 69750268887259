@charset "UTF-8";

@font-face {
    font-family: gzn;
    src: url("../fonts/gzn.eot");
    src: url("../fonts/gzn.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gzn.woff") format("woff"),
        url("../fonts/gzn.ttf") format("truetype"),
        url("../fonts/gzn.svg#gzn") format("svg");
    font-weight: 400;
    font-style: normal;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
    font-family: gzn;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-angle-down {
    &::before {
        content: "\61";
    }
}

.icon-angle-left {
    &::before {
        content: "\62";
    }
}

.icon-angle-right {
    &::before {
        content: "\63";
    }
}

.icon-angle-up {
    &::before {
        content: "\64";
    }
}

.icon-cross {
    &::before {
        content: "\65";
    }
}

@font-face {
    font-family: RuckSack;
    src: url("../fonts/RuckSack-Light.eot");
    src: local("RuckSack Light"), local("RuckSack-Light"),
        url("../fonts/RuckSack-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/RuckSack-Light.woff2") format("woff2"),
        url("../fonts/RuckSack-Light.woff") format("woff"),
        url("../fonts/RuckSack-Light.ttf") format("truetype"),
        url("../fonts/RuckSack-Light.svg#RuckSack-Light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: RuckSack;
    src: url("../fonts/RuckSack-Book.eot");
    src: local("RuckSack-Book"),
        url("../fonts/RuckSack-Book.eot?#iefix") format("embedded-opentype"),
        url("../fonts/RuckSack-Book.woff2") format("woff2"),
        url("../fonts/RuckSack-Book.woff") format("woff"),
        url("../fonts/RuckSack-Book.ttf") format("truetype"),
        url("../fonts/RuckSack-Book.svg#RuckSack-Book") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: RuckSack;
    src: url("../fonts/RuckSack-Medium.eot");
    src: local("RuckSack-Medium"),
        url("../fonts/RuckSack-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/RuckSack-Medium.woff2") format("woff2"),
        url("../fonts/RuckSack-Medium.woff") format("woff"),
        url("../fonts/RuckSack-Medium.ttf") format("truetype"),
        url("../fonts/RuckSack-Medium.svg#RuckSack-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: RuckSack;
    src: url("../fonts/RuckSack-Demi.eot");
    src: local("RuckSack Demi"), local("RuckSack-Demi"),
        url("../fonts/RuckSack-Demi.eot?#iefix") format("embedded-opentype"),
        url("../fonts/RuckSack-Demi.woff2") format("woff2"),
        url("../fonts/RuckSack-Demi.woff") format("woff"),
        url("../fonts/RuckSack-Demi.ttf") format("truetype"),
        url("../fonts/RuckSack-Demi.svg#RuckSack-Demi") format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: RuckSack;
    src: url("../fonts/RuckSack-Bold.eot");
    src: local("RuckSack Bold"), local("RuckSack-Bold"),
        url("../fonts/RuckSack-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/RuckSack-Bold.woff2") format("woff2"),
        url("../fonts/RuckSack-Bold.woff") format("woff"),
        url("../fonts/RuckSack-Bold.ttf") format("truetype"),
        url("../fonts/RuckSack-Bold.svg#RuckSack-Bold") format("svg");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: RuckSack;
    src: url("../fonts/RuckSack-Black.eot");
    src: local("RuckSack-Black"),
        url("../fonts/RuckSack-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/RuckSack-Black.woff2") format("woff2"),
        url("../fonts/RuckSack-Black.woff") format("woff"),
        url("../fonts/RuckSack-Black.ttf") format("truetype"),
        url("../fonts/RuckSack-Black.svg#RuckSack-Black") format("svg");
    font-weight: 900;
    font-style: normal;
}