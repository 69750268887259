.dg-btn {
    @extend .button;
    text-align: center;
    flex-direction: column;
}

.dg-btn--cancel {
    @extend .button--white;
}

.dg-btn--ok {
    @extend .button--primary;
}

.dg-btn-loader .dg-circle {
    background-color: map-deep-get($colors, "white", "default");
}
