.attachments-item {
    $action_size: 20;

    &__name {
        &--error {
            color: map-deep-get($colors, "validation", "invalid");
        }
    }

    &__actions {
        width: rem-calc($action_size);
        height: rem-calc($action_size);
        position: absolute;
        top: 50%;
        right: rem-calc(($action_size / 2));
        margin-top: rem-calc((0 - ($action_size / 2)));
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon,
    &__action {
        border: none;
        background: none transparent;
        padding: 0;
        margin: 0;
        display: inline-block;

        svg {
            width: 100%;
            height: 100%;
        }

        &--delete {
            display: inline-block;
            height: 0.8rem;
            width: 0.8rem;

            svg {
                fill: map-deep-get($colors, "validation", "invalid");
            }
        }
    }

    &__action {
        cursor: pointer;
    }
}