.carpool-block {
    $elm: &;

    &--expanded,
    &--highlight {
        background-color: map-deep-get($colors, "brand", "primary-light");

        &:hover {
            background-color: map-deep-get($colors, "brand", "primary-light");
        }

        #{$elm}__expand-label {
            color: map-deep-get($colors, "blue", "dark");
        }
    }

    &__header {
        cursor: default;
        margin-bottom: 0;
    }

    &__label {
        color: map-deep-get($colors, "gray", "darker");
    }

    &__expand-label {
        cursor: pointer;
    }

    &__expand-icon {
        margin-left: .5em;
    }
}