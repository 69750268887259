.account-info {
    &__avatar {
        background: map-deep-get($colors, "brand", "primary");
        border-radius: 100%;
        display: inline-block;
        //padding-top: 100%;
        text-align: center;
        vertical-align: middle;
        //width: 100%;
        overflow: hidden;
        margin-right: 5px;
        position: relative;
        width: 140px;
        height: 140px;
    }

    &__character {
        position: absolute;
        top: 50%;
        left: 50%;
        line-height: 1em;
        transform: translate(-50%, -50%);
        color: map-deep-get($colors, "white", "default");
        font-size: 10vw;

        @include media-breakpoint-up(xl) {
            font-size: 4vw;
        }
        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(50);
        }
    }

    &__photo {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__link {
        &--photo {
            margin-top: 1em;
            display: block;
            text-align: center;
        }
    }

    &__list {
        margin-bottom: 1em;
    }

    &__loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "white", "default"), .6);

        svg {
            width: rem-calc(30);
            height: rem-calc(30);
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: rem-calc(-15);
            margin-top: rem-calc(-15);
        }
    }
}