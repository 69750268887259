.footer-nav {
    @include media-breakpoint-up(md) {
        margin-left: -#{$grid-gutter-width / 3};
        margin-right: -#{$grid-gutter-width / 3};
    }

    &__item {
        text-align: center;

        @include media-breakpoint-up(md) {
            display: inline-block;
            padding-left: #{$grid-gutter-width / 3};
            padding-right: #{$grid-gutter-width / 3};
        }
    }

    &__link {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "gray", "darker");
        font-weight: 400;
        text-decoration: underline;
        transition: $transition-default;

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }
    }
}