.blocks--slider {
    margin-bottom: 5px;
    flex-wrap: wrap;

    // vendor class
    /* stylelint-disable */
    &.flickity-enabled {
        .block {
            margin-bottom: 0;
            min-height: 100%;
            margin-right: #{$grid-gutter-width / 2};
            top: 0;
            width: calc(100% - #{$grid-gutter-width});
        }

        @include media-breakpoint-up(lg) {
            margin-right: 0;
            top: auto;
            width: 100%;
        }
    }
    /* stylelint-enable */
}